import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Button,
  Divider,
  Typography,
  styled,
} from '@mui/material'
import React from 'react'
import { Box } from '@mui/system'
import { AuthContext } from '../../contexts/authContext'
import { getApiV2 } from '../../libs/api'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { CompanyFinderInputs } from '../../components/CompanyFinderInputs'
import { DataTable } from '../../components/DataTable'
import { useNavigate } from 'react-router-dom'

export const CompanyFinder = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const auth = React.useContext(AuthContext)

  const [results, setResults] = React.useState<any[]>([])
  const inputRef = React.useRef<any>(null)

  const headers = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Title',
      field: 'title',
    },
    {
      label: 'Company',
      field: 'company',
    },
    {
      label: 'Actions',
      field: 'linkedinUrl',
      render: (linkedinUrl: string) => (
        <Button variant="outlined" onClick={() => nav(`/analysis/${encodeURIComponent(linkedinUrl)}`)}>
          Analyze
        </Button>
      ),
    },
  ]

  const postQuery = async (params: any) => {
    console.log(`Fetch with ${params}`)
    queryClient.setQueryData(['contact-analysis'], {})

    try {
      const response = await (await getApiV2(auth)).get('/companies', { params })

      console.log(response.data)

      setResults(response.data.results)
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 403) {
        enqueueSnackbar('Insufficient credits', { variant: 'error' })
      }
    }
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs>
          <Typography color="text.primary">Company finder</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{mb:2, mt:2}} />

      <Alert severity="info">
        <AlertTitle>Instructions</AlertTitle>
        <p>
          Find the best contact person for your target company. Just type in the name of the target company and find all contacts.
        </p>
      </Alert>

      <CompanyFinderInputs myRef={inputRef} onSearch={postQuery} />

      <DataTable items={results} isLoading={false} headers={headers} />
    </>
  )
}
