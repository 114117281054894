import { Tooltip, Typography } from '@mui/material';
import { EnumView } from './EnumView';

interface ContactPersonalityProps {
  analysis: any;
  isLoading: boolean;
}

export const ContactPersonality = (props: ContactPersonalityProps) => (
  <>  
    <Typography variant="h6">Personality</Typography>

    <Tooltip title="The confidence of the personality analysis." placement="left">
      <EnumView
        sx={{ mb: 2 }}
        title="Confidence of personality analysis"
        options={['Very low', 'Low', 'High', 'Very high']}
        selectedIndex={props.analysis?.personality?.confidence?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="This trait reflects a person's willingness to explore new ideas, experiences, and perspectives. High scorers tend to be creative, open-minded, and curious, while low scorers may be more conventional and resistant to change."
      placement="left"
    >
      <EnumView
        title="Openness"
        options={['Very closed', 'Closed', 'Open', 'Very open']}
        selectedIndex={props.analysis?.personality?.ocean?.O?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="Conscientious individuals are organized, responsible, and dependable. They tend to set and achieve goals, pay attention to details, and are generally seen as reliable and diligent."
      placement="left"
    >
      <EnumView
        title="Conscientiousness"
        options={['Very easygoing', 'Easygoing', 'Conscientious', 'Very conscientious']}
        selectedIndex={props.analysis?.personality?.ocean?.C?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="Extroversion refers to the degree of sociability and outgoingness in a person. High scorers are typically outgoing, energetic, and enjoy social interactions, while low scorers may prefer solitude and quiet."
      placement="left"
    >
      <EnumView
        title="Extraversion"
        options={['Very introverted', 'Introverted', 'Extroverted', 'Very extroverted']}
        selectedIndex={props.analysis?.personality?.ocean?.E?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="This trait relates to a person's interpersonal style and willingness to cooperate with others. High scorers are generally warm, empathetic, and cooperative, while low scorers may be more competitive or skeptical."
      placement="left"
    >
      <EnumView
        title="Agreeableness"
        options={['Very low agreeableness', 'Low agreeableness', 'High agreeableness', 'Very high agreeableness']}
        selectedIndex={props.analysis?.personality?.ocean?.A?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="Neuroticism measures emotional stability and resilience. High scorers tend to be more anxious, moody, and prone to negative emotions, while low scorers are typically calmer and better at handling stress."
      placement="left"
    >
      <EnumView
        title="Neuroticism"
        options={['Very balanced', 'Balanced', 'Sensitive', 'Very sensitive']}
        selectedIndex={props.analysis?.personality?.ocean?.N?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
    <Tooltip
      title="Refers to tendency to embrace and use new products or technologies. High scorers adapt new eagerly and influence others to do so as well."
      placement="left"
    >
      <EnumView
        sx={{ mt: 2 }}
        title="Early adopter"
        options={['Very low', 'Low', 'High', 'Very high']}
        selectedIndex={props.analysis?.personality?.earlyAdopter?.classIndex}
        isLoading={props.isLoading}
      />
    </Tooltip>
  </>
);
