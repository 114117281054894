import React from 'react';
import { MenuItem, Button, FormHelperText, Box, FormGroup, FormControlLabel, Checkbox, FormControl, Grid } from '@mui/material';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { LoadingButton } from '@mui/lab';

interface TaskSearchByNameFormProps {
    onSubmit: (data:{[key: string]: string}) => void;
}

export const TaskSearchByNameForm = (props:TaskSearchByNameFormProps) => {
    const [data, setData] = React.useState({
        firstName: '',
        lastName: '',
        companyName: ''
    })

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const formRef = React.useRef<ValidatorForm>(null);
    const [isFormValid, setIsFormValid] = React.useState(false);

    React.useEffect(() => {
        const validate = async () => {
            if (formRef !== null && formRef.current !== null) {
                setIsFormValid(await formRef.current.isFormValid(true));
            }
        }

        validate();
    }, [data])

    const handleFieldChange = (event:any) => {
        const { name, value, type } = event.target;
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await props.onSubmit(data);
        setIsSubmitting(false);
    }

    return (
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
                sx={{borderWidth:'5px',display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start'}}
            >
                <Grid container gap={1}>

                    <Grid item sm={12}>
                        <TextValidator
                                label="First name"
                                onChange={handleFieldChange}
                                name="firstName"
                                validators={["required", "maxStringLength:48"]}
                                errorMessages={["Kenttä on pakollinen", "Maksimipituus 48 merkkiä"]}
                                value={data.firstName}
                                fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextValidator
                                label="Last name"
                                onChange={handleFieldChange}
                                name="lastName"
                                validators={["required", "maxStringLength:48"]}
                                errorMessages={["Kenttä on pakollinen", "Maksimipituus 48 merkkiä"]}
                                value={data.lastName}
                                fullWidth />
                    </Grid>
                    <Grid item sm={12}>
                        <TextValidator
                                label="Company name"
                                onChange={handleFieldChange}
                                name="companyName"
                                validators={["required", "maxStringLength:48"]}
                                errorMessages={["Kenttä on pakollinen", "Maksimipituus 48 merkkiä"]}
                                value={data.companyName}
                                fullWidth />
                    </Grid>

                    <Grid item sm={6}>
                        <LoadingButton loading={isSubmitting} type="submit" disabled={!isFormValid} variant="contained">Search</LoadingButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
}