import { Tab, Button, Box, Divider, Breadcrumbs, Link, Typography, Stepper, Step, StepLabel, Grid } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import JSONPretty from 'react-json-pretty'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { TaskProfileForm } from '../../forms/taskProfileForm'
import { TaskSearchCustomForm } from '../../forms/taskSearchCustomFrom'
import { TaskSearchByNameForm } from '../../forms/taskSearchByNameForm'
import { getApi } from '../../libs/api'

export const TaskCreate = () => {
  const auth = React.useContext(AuthContext)
  const [searchJson, setSearchJson] = React.useState('')
  const [profileJson, setProfileJson] = React.useState('')
  const [currentTab, setCurrentTab] = React.useState(1)
  const [linkedinUrl, setLinkedinUrl] = React.useState<string | undefined>(undefined)

  const onSearchByName = async (data: { [key: string]: string }) => {
    const response = await (
      await getApi(auth)
    ).get('tasks/linkedin-urls', {
      params: data,
    })

    setLinkedinUrl(response.data.linkedinUrl)
    setSearchJson(JSON.stringify(response.data))
  }

  const onSearchCustom = async (data: { [key: string]: string }) => {
    const response = await (
      await getApi(auth)
    ).get('tasks/linkedin-urls', {
      params: {
        query: data.query,
        requiredResultStrings: data.requiredResultStrings,
      },
    })

    setLinkedinUrl(response.data.linkedinUrl)
    setSearchJson(JSON.stringify(response.data))
  }

  const onProfile = async (linkedinUrl: string, features: string[]) => {
    const response = await (
      await getApi(auth)
    ).get('tasks/profiles', {
      params: {
        linkedinUrl: linkedinUrl,
        features: features.join(','),
      },
    })

    setProfileJson(JSON.stringify(response.data))
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const sendToProfiler = () => {
    setCurrentTab(2)
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">Tasks</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <TabContext value={currentTab + ''}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Search" value="1" />
            <Tab label="Profiler" value="2" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <Grid container p={2} gap={1}>
            <Grid item xs={6}>
              <Typography variant="h2">Search by name</Typography>
              <TaskSearchByNameForm onSubmit={onSearchByName} />

              <Typography variant="h2" mt={4}>
                Custom search
              </Typography>
              <TaskSearchCustomForm onSubmit={onSearchCustom} />
            </Grid>
            <Grid p={2} item xs={5} sx={{ backgroundColor: '#eee' }}>
              <JSONPretty data={searchJson} />
              {linkedinUrl !== undefined && (
                <Button variant="contained" onClick={sendToProfiler}>
                  Send to profiler
                </Button>
              )}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value="2">
          <Grid container p={2} gap={1}>
            <Grid item xs={6}>
              <Typography variant="h2">Profile</Typography>
              <TaskProfileForm linkedinUrl={linkedinUrl ?? ''} onSubmit={onProfile} />
            </Grid>
            <Grid p={2} item xs={5} sx={{ backgroundColor: '#eee' }}>
              <JSONPretty data={profileJson} />
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </>
  )
}
