import React from 'react'

import { Alert, Box, Button, Container, Link, List, ListItem, Typography } from '@mui/material'

import { AuthContext } from '../../contexts/authContext'
import { SignUpForm } from '../../forms/signUpForm'
import { SignUpVerifyForm } from '../../forms/signUpVerifyForm'
import { Done } from '@mui/icons-material'

enum Phase {
  SIGNUP = 1,
  COMPLETED = 2
}

export const SignUp: React.FunctionComponent = () => {
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState('')
  const [phase, setPhase] = React.useState(Phase.SIGNUP)

  const authContext = React.useContext(AuthContext)

  const onSignUp = async (email: string, password: string) => {
    setEmail(email)

    try {
      await authContext.signUpWithEmail(email, email, password)
      setPhase(Phase.COMPLETED)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const signUp = (
    <>
      <SignUpForm onSubmit={onSignUp} />

      <Box m={1}>
        <Link variant="body2" href="#/">
          Already have an account?
        </Link>
      </Box>

      <Typography sx={{ mt: 5 }} variant="body1">
        
        Follow this step-by-step setup documentation to start using the Traitspotter.
      </Typography>
      <List
        sx={{
          listStyleType: 'numbered',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}>
        {' '}
        <ListItem>Sign-up with your email address and chosen password</ListItem>
        <ListItem>Click a link sent yo your email to verify your email address and login</ListItem>
        <ListItem>Read and accept the terms of service</ListItem>
        <ListItem>Use in browser or connect to HubSpot from "HubSpot" tab</ListItem>
      </List>
    </>
  )

  const completed = (
    <Box>
      <Box sx={{textAlign: 'center'}}>
        <Done fontSize="large" color="success" />
      </Box>
      <Typography sx={{ fontSize: '140%' }}>Welcome to Traitspotter</Typography>
      <Typography>We have sent you an email with auto login link. Now please check your email and we'll guide you further.</Typography>
    </Box>
  )

  return (
    <>
      {phase === Phase.SIGNUP && <Typography sx={{ mb: 3, marginRight: 'auto' }} component="h1" variant="h5">
        Sign up
      </Typography>}{' '}
      {phase === Phase.SIGNUP && signUp}
      {phase === Phase.COMPLETED && completed}
      {error !== '' && (
        <Alert sx={{ width: '100%', mt: 2 }} severity="error">
          {error}
        </Alert>
      )}
    </>
  )
}
