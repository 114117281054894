import React from 'react'
import { Button, Grid } from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { LoadingButton } from '@mui/lab'

interface SignUpFormProps {
  onSubmit: (email: string, password: string) => void
}

export const SignUpForm = (props: SignUpFormProps) => {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    repeatPassword: '',
  })

  const valuesRef = React.useRef<typeof values>()
  valuesRef.current = values

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isFormValid, setIsFormValid] = React.useState(false)

  const formRef = React.useRef<ValidatorForm>(null)

  React.useEffect(() => {
    const validate = async () => {
      if (formRef !== null && formRef.current !== null) {
        setIsFormValid(await formRef.current.isFormValid(true))
      }
    }

    validate()
  }, [values])

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== valuesRef.current?.password) {
        return false
      }
      return true
    })
  }, [])

  const handleChange = (event: any) => {
    const { name, value, type } = event.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await props.onSubmit(values.email, values.password)
    setIsSubmitting(false)
  }

  return (
    <ValidatorForm
      ref={formRef}
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      sx={{
        borderWidth: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}>
      <Grid container gap={1}>
        <Grid item xs={12}>
          <TextValidator
            label="Email"
            onChange={handleChange}
            name="email"
            validators={['required']}
            errorMessages={['Email is required']}
            value={values.email}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            label="Password"
            name="password"
            id="password"
            type="password"
            validators={['required']}
            errorMessages={['Password is required']}
            onChange={handleChange}
            value={values.password}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            label="Repeat password"
            name="repeatPassword"
            id="repeatPassword"
            type="password"
            validators={['required', 'isPasswordMatch']}
            errorMessages={['Password confirmation is required', 'Passwords do not match']}
            onChange={handleChange}
            value={values.repeatPassword}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton fullWidth loading={isSubmitting} type="submit" disabled={!isFormValid} variant="contained">
            Sign up
          </LoadingButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}
