import React from 'react'
import {
  Box,
  Divider,
  Breadcrumbs,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from '@mui/material'
import { AuthContext } from '../contexts/authContext'
import { DataTable } from '../components/DataTable'
import { ConfirmDialog } from '../components/ConfirmDialog'
import { LoadingButton } from '@mui/lab'
import { getApiV2 } from '../libs/api'
import { enqueueSnackbar } from 'notistack'

interface Apikey {
  id: string
  name: string
  key: string
}

export const ApiKeys = () => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [items, setItems] = React.useState<Apikey[]>([])
  const [deleteId, setDeleteId] = React.useState('0')
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)
  const [isCreateFormVisible, setIsCreateFormVisible] = React.useState<boolean>(false)
  const [isKeyModalVisible, setIsKeyModalVisible] = React.useState<boolean>(false)
  const [apikeyName, setApikeyName] = React.useState('')
  const auth = React.useContext(AuthContext)

  const headers = [
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Actions',
      field: 'id',
      render: (data: any) => (
        <Button variant="outlined" color="secondary" onClick={() => onDelete(data)}>
          Delete
        </Button>
      ),
    },
  ]

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsFetching(true)

    try {
      setItems((await (await getApiV2(auth)).get('/apikeys', {})).data)
    } catch (err) {
      enqueueSnackbar('Updated failed. Try refreshing the page.', { variant: 'warning' })
      console.log(err)
    }

    setIsFetching(false)
  }

  const onDelete = async (id: string) => {
    setDeleteId(id)
    setDeleteDialogOpen(true)
  }

  const saveApiKey = async () => {
    try {
      const response = await (await getApiV2(auth)).post('/apikeys', { name: apikeyName })
      const newItems: any = items
      newItems.push(response.data)

      setIsKeyModalVisible(true)
      setIsCreateFormVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  const confirmDelete = async () => {
    await (await getApiV2(auth)).delete(`/apikeys/${deleteId}`, {})
    setItems(items.filter((i: any) => i.id !== deleteId))
    enqueueSnackbar('API key deleted', { variant: 'success' })
  }

  const onChange = (event: any) => {
    const { value } = event.target

    setApikeyName(value)
  }

  return (
    <>
      <ConfirmDialog
        sx={{ minWidth: '600px' }}
        title="Are you sure?"
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onConfirm={confirmDelete}
      />

      <Dialog open={isCreateFormVisible} fullWidth maxWidth="sm">
        <DialogTitle>Create new API key</DialogTitle>
        <DialogContent>
          <TextField
            required
            autoFocus
            margin="dense"
            id="key"
            label="Key name"
            type="text"
            fullWidth
            variant="standard"
            value={apikeyName}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => setIsCreateFormVisible(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={saveApiKey}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ m: 1 }}>
          <Breadcrumbs>
            <Typography color="text.primary">API keys</Typography>
          </Breadcrumbs>
        </Box>

        <Button variant="contained" onClick={() => setIsCreateFormVisible(true)}>
          Create new key
        </Button>
      </div>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <DataTable isLoading={isFetching} items={items as any} headers={headers} />

      <Dialog maxWidth="md" fullWidth open={isKeyModalVisible}>
        <DialogTitle>API key saved</DialogTitle>
        <DialogContent>
          Your new API key {}
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            {items.length > 0 && items[items.length - 1].name}
          </Typography>
          {} has been created. Copy the key below:
          <Divider />
          {items.length > 0 && items[items.length - 1].key}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsKeyModalVisible(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
