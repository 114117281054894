import React, { useState, useEffect, useContext } from 'react'

import * as cognito from '../libs/cognito'
import axios from 'axios'

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface UserDocument {
  id: string
  name: string
  link: string
  acceptedVersion: string
  latestVersion: string
}

export interface IAuth {
  sessionInfo?: {
    username?: string
    email?: string
    sub?: string
    accessToken?: string
    refreshToken?: string
    groups?: string[]
  }
  userInfo?: UserInfo
  attrInfo?: any
  authStatus?: AuthStatus
  hasGroup?: any
  getSessionInfo?: any
  signInWithChallenge?: any
  signInWithPassword?: any
  signUpWithEmail?: any
  signOut?: any
  sendLoginLink?: any;
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  updateSessionInfo?: any
  setCreditsLeft?: (credits: number) => void;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

interface SessionInfo {
  accessToken: string
  refreshToken: string
  groups?: string[]
  expiry: number
}

interface UserInfo {
  pendingDocuments: UserDocument[];
  credits: number;
}

export const AuthContext = React.createContext(defaultState)

type Props = {
  children: JSX.Element | JSX.Element[]
}

type PropsWithGroup = {
  children: JSX.Element | JSX.Element[]
  group: string
}

export const AuthIsSignedIn: React.FunctionComponent<Props> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn: React.FunctionComponent<Props> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

export const AuthRoleRequired: React.FunctionComponent<PropsWithGroup> = ({ children, group }) => {
  const { sessionInfo }: IAuth = useContext(AuthContext)

  return <>{sessionInfo?.groups?.includes(group) ? children : null}</>
}

const AuthProvider: React.FunctionComponent<Props> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>()
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const [attrInfo, setAttrInfo] = useState([])

  useEffect(() => {
    updateSessionInfo()
  }, [])

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  const getSessionInfo = async (): Promise<SessionInfo> => {
    if (authStatus !== AuthStatus.SignedIn) {
      throw new Error('Not signed it')
    }

    if (sessionInfo?.expiry! < (new Date().getTime() / 1000 + 600)) {
      const info = await updateSessionInfo()

      if (info !== undefined) {
        return info
      }

      throw new Error('Could not renew session')
    }

    return sessionInfo!
  }

  const hasGroup = (group: string) => {
    return sessionInfo?.groups?.includes(group)
  }

  const setCreditsLeft = (credits: number) => {
    setUserInfo({...userInfo!, credits })
  }

  async function updateSessionInfo() {
    try {
      const session: any = await getSession()

      const info = {
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
        groups: session.accessToken.payload['cognito:groups'],
        expiry: session.accessToken.payload.exp,
      }
      setSessionInfo(info)

      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)

      const attr: any = await getAttributes()
      setAttrInfo(attr)
      setAuthStatus(AuthStatus.SignedIn)

      const userInfo = await getUserInfo(info.accessToken)
      setUserInfo(userInfo)

      return info
    } catch (err) {
      console.log(err)
      setAuthStatus(AuthStatus.SignedOut)
    }
  }

  async function getUserInfo(accessToken: string) {
    const { data } = await axios.get(`${import.meta.env.VITE_API_V2_URL}/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return data
  }

  const signInWithChallenge = async (username: string, challenge: string) => {
    try {
      await cognito.signInWithChallenge(username, challenge)
      setAuthStatus(AuthStatus.SignedIn)
      await updateSessionInfo()
    } catch (err) {
      if (authStatus !== AuthStatus.SignedOut) {
        setAuthStatus(AuthStatus.SignedOut)
      }

      throw err
    }
  }

  async function signInWithPassword(username: string, password: string) {
    try {
      await cognito.signInWithPassword(username, password)
      setAuthStatus(AuthStatus.SignedIn)
      await updateSessionInfo()
    } catch (err) {
      if (authStatus !== AuthStatus.SignedOut) {
        setAuthStatus(AuthStatus.SignedOut)
      }

      throw err
    }
  }

  async function signUpWithEmail(username: string, email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(username, email, password)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      var hash: {[key: string]: string} = {};
      const attr = await cognito.getAttributes()
      
      for (const item of (attr as any)) {
        hash[item.Name] = item.Value;
      }

      return hash;
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    userInfo,
    attrInfo,
    hasGroup,
    getSessionInfo,
    signUpWithEmail,
    signInWithChallenge,
    signInWithPassword,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    updateSessionInfo,
    setCreditsLeft
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
