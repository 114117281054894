import { styled, alpha } from '@mui/material/styles'
import { ArrowDropDown, LinkedIn } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  Menu,
  MenuProps,
  Tab,
  Tabs,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

const DenseTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-input': { paddingTop: 0 },
  grow: 1,
  width: '100%',
  backgroundColor: 'transparent',
}))

const SuccessBox = styled(Box)(({ theme }) => ({
  margin: 'auto',
  lineHeight: '16px',
  padding: '0 8px',
  color: 'white',
  border: `1px solid ${theme.palette.success.dark}`,
  backgroundColor: theme.palette.success.main,
  fontSize: '12px',
  fontWeight: 800,
  textTransform: 'uppercase',
  borderRadius: '5px',
  boxShadow: `inset 0 0 8px ${theme.palette.success.dark}`,
}))

interface ContactInputsProps {
  analysis: any
  previous: any[]
  onSelectPrevious: (id: string) => void
  onSearch: (contactProps: any) => Promise<void>
  myRef: any
}

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const ContactInputs = (props: ContactInputsProps) => {
  const [queryErrors, setQueryErrors] = React.useState<any>({})
  const [query, setQuery] = React.useState<any>({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [previousAnchorEl, setPreviousAnchorEl] = React.useState<null | HTMLElement>(null)
  const [tab, setTab] = React.useState(0)
  const previousOpen = Boolean(previousAnchorEl)

  props.myRef.current = {
    reset: () => {
      setQuery({})
    },

    searchWith: async (q: any) => {
      setQuery(q)
      setTab(q.linkedinUrl ? 1 : 0)
      setIsLoading(true)
      await search(q.linkedinUrl ? 1 : 0, q)
      setIsLoading(false)
    },
  }

  React.useEffect(() => {
    validate()
  }, [query])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPreviousAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setPreviousAnchorEl(null)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const validate = () => {
    let errors: any = {}

    if (tab === 0) {
      if (!query.name) {
        errors['name'] = 'Name is required'
      } else if (query.name.split(' ').length < 2) {
        errors['name'] = 'Name must contain first name and last name'
      }

      if (!query.title && !query.company) {
        errors['title'] = 'Either title or company must be present'
        errors['company'] = 'Either title or company must be present'
      }
    } else {
      if (!query.linkedinUrl) {
        errors['linkedinUrl'] = 'Linkedin URL is required'
      }
    }

    setQueryErrors(errors)
  }

  const search = async (t: number, q: any) => {
    console.log('Search with', q, t)
    setIsLoading(true)
    if (t === 0) {
      await props.onSearch({ name: q.name, title: q.title, company: q.company })
    } else {
      await props.onSearch({ linkedinUrl: q.linkedinUrl })
    }
    setIsLoading(false)
  }

  return (
    <>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Find by name" />
        <Tab label="Find by Linkedin URL" />
      </Tabs>

      <Box
        sx={{
          mb: 0.5,
          mt: 1,
          backgroundColor: '#eee',
          display: 'flex',
          alignItems: 'center',
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}>
        <Box sx={{ ml: 1, flexGrow: 1, flexDirection: 'column', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <TabPanel value={tab} index={0}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <DenseTextField
                  helperText={queryErrors['name']}
                  id="name"
                  sx={{
                    mb: 2,
                  }}
                  label="Name"
                  value={query.name}
                  variant="standard"
                  disabled={props.analysis.status === 'PENDING'}
                  onChange={(e) => setQuery({ ...query, name: e.target.value })}
                />
              </Box>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', ml: 5 }}>
                <DenseTextField
                  helperText={queryErrors['title']}
                  sx={{
                    mb: 2,
                  }}
                  id="title"
                  label="Title"
                  value={query.title}
                  variant="standard"
                  disabled={props.analysis.status === 'PENDING'}
                  onChange={(e) => setQuery({ ...query, title: e.target.value })}
                />

                <DenseTextField
                  helperText={queryErrors['company']}
                  id="company"
                  label="Company"
                  value={query.company}
                  variant="standard"
                  disabled={props.analysis.status === 'PENDING'}
                  onChange={(e) => setQuery({ ...query, company: e.target.value })}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', flexGrow: 0.5 }}>
                <DenseTextField
                  helperText={queryErrors['linkedinUrl']}
                  id="linkedinUrl"
                  sx={{
                    mb: 2,
                  }}
                  label="Linkedin URL"
                  value={query.linkedinUrl}
                  variant="standard"
                  disabled={props.analysis.status === 'PENDING'}
                  onChange={(e) => setQuery({ ...query, linkedinUrl: e.target.value })}
                />
              </Box>
            </Box>
          </TabPanel>
          <Box sx={{ marginLeft: 'auto', mt: 1, display: 'flex' }}>
            <Button
              id="demo-customized-button"
              aria-controls={previousOpen ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={previousOpen ? 'true' : undefined}
              variant="contained"
              color="secondary"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ mr: 1 }}>
              Previous searches
            </Button>

            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={previousAnchorEl}
              open={previousOpen}
              onClose={handleClose}>
              {props.previous.map((prev) => (
                <MenuItem
                  onClick={() => {
                    props.onSelectPrevious(prev.id)
                    setQuery({ name: '', title: '', company: '', linkedinUrl: '' })
                    handleClose()
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: `1px solid rgba(0,0,0,0.12)`,
                    justifyContent: 'flex-start',
                  }}>
                  {prev.linkedinUrl ? (
                    <>
                      <Typography sx={{ width: '100%', fontSize: '16px' }}>{prev.linkedinUrl}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography sx={{ width: '100%', fontSize: '16px' }}>
                        {prev.firstName} {prev.lastName}
                      </Typography>
                      <Typography sx={{ width: '100%', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
                        {prev.company}
                      </Typography>
                      <Typography sx={{ width: '100%', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
                        {prev.title}
                      </Typography>
                    </>
                  )}
                </MenuItem>
              ))}
              {props.previous.length == 0 && <Box m={1}>No last searches</Box>}
            </StyledMenu>

            <LoadingButton
              size="small"
              variant="contained"
              color="secondary"
              disabled={Object.keys(queryErrors).length > 0}
              onClick={() => search(tab, query)}
              loading={isLoading}>
              Search
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  )
}
