import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import React from 'react'
import { Alert } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getApiV2 } from '../libs/api'
import { AuthContext } from '../contexts/authContext'
import { enqueueSnackbar } from 'notistack'

interface HubspotParam {
  id: string
  title: string
}

export const Preferences = () => {
  const auth = React.useContext(AuthContext);
  const [hubspotParams, setHubspotParams] = React.useState<string[]>([])

  const { isFetching: isLoading, data } = useQuery(
    ['preferences'],
    async () => {
      console.log(`Loading prefereces`)

      const response = await (await getApiV2(auth)).get(`/user/preferences`)

      setHubspotParams(response.data.hubspot_params ?? []);

      return response.data
    },
  )

  const { mutate: savePreferences } = useMutation(
    async () => {
      return await (
        await getApiV2(auth)
      ).patch(`/user/preferences`, {
        hubspot_params: hubspotParams,
      })
    },
    {
      onSuccess: (response) => {
        if ([200].includes(response.status)) {
          console.log(response)
          enqueueSnackbar('Preferences saved', { variant: 'success' })

        }
      },
      onError: () => {
        enqueueSnackbar('Save failed', { variant: 'error' })
      },
    }
  )

  const availableParams: HubspotParam[] = [
    {
      id: 'linkedinUrl',
      title: 'Core / Linkedin URL',
    },
    {
      id: 'age.age',
      title: 'Age',
    },
    {
      id: 'currentPosition.title',
      title: 'Current position / Job Title',
    },
    {
      id: 'currentPosition.company',
      title: 'Current position / Company',
    },
    {
      id: 'currentPosition.monthsInRole',
      title: 'Current position / Months in role',
    },
    {
      id: 'currentPosition.monthsInCompany',
      title: 'Current position / Months in company',
    },
    {
      id: 'currentPosition.function',
      title: 'Current position / Function',
    },
    {
      id: 'currentPosition.level',
      title: 'Current position / Level',
    },
    {
      id: 'education.degree',
      title: 'Education / Degree',
    },
    {
      id: 'education.school',
      title: 'Education / School',
    },
    {
      id: 'education.ISCED11',
      title: 'Education / ISCED11',
    },
    {
      id: 'experience.totalMonths',
      title: 'Experience / Total months',
    },
    {
      id: 'experience.numPositions',
      title: 'Experience / Number of positions',
    },
    {
      id: 'gender',
      title: 'Gender',
    },
    {
      id: 'languages',
      title: 'Languages',
    },
    {
      id: 'location',
      title: 'Location',
    },
    {
      id: 'personality.confidence.class',
      title: 'Personality / Confidence',
    },
    {
      id: 'personality.ocean.O.class',
      title: 'Personality / OCEAN / Openness',
    },
    {
      id: 'personality.ocean.C.class',
      title: 'Personality / OCEAN / Conscientiousness',
    },
    {
      id: 'personality.ocean.E.class',
      title: 'Personality / OCEAN / Extraversion',
    },
    {
      id: 'personality.ocean.A.class',
      title: 'Personality / OCEAN / Agreeableness',
    },
    {
      id: 'personality.ocean.N.class',
      title: 'Personality / OCEAN / Neuroticism',
    },
    {
      id: 'personality.earlyAdopter.class',
      title: 'Personality / Early Adopter',
    },
  ]

  const handleSelect = (event: any) => {
    const id = event.target.value

    setHubspotParams([...hubspotParams, id])
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs>
          <Typography color="text.primary">Preferences</Typography>
        </Breadcrumbs>
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h6">HubSpot integration configuration</Typography>

      <Alert severity="info" sx={{ mb: 2 }}>
        This is personal configuration and only affects your own view. Select which parameters you wish to see instantly
        in HubSpot Traitspotter panel. You will always have access to all data via Traitspotter button. This quick view
        is not available in free on-demand mode.
      </Alert>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Add parameter</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age" value="" onChange={handleSelect}>
          {availableParams
            .filter((param) => !hubspotParams.includes(param.id))
            .map((param, index) => (
              <MenuItem value={param.id}>{param.title}</MenuItem>
            ))}
        </Select>
      </FormControl>

      <Typography sx={{ mt: 1 }} variant="body2">
        Selected parameters (click to remove)
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexFlow: 'column', alignItems: 'start', maxWidth: '300px' }}>
        {hubspotParams
          .map((param) => availableParams.find((p) => p.id === param))
          .map((param, index) => (
            <Button
              key={index}
              variant="text"
              onClick={() => setHubspotParams(hubspotParams.filter((p) => p !== param!.id))}>
              {index + 1}. {param!.title}
            </Button>
          ))}
        {hubspotParams.length === 0 && <Typography variant="caption">No parameters selected</Typography>}
      </Box>

      <Button variant="contained" sx={{ mt: 2 }} onClick={() => savePreferences()}>
        Save
      </Button>
    </>
  )
}
