import React, { useContext } from 'react'
import MaterialReactTable, { MRT_ColumnFiltersState, MRT_SortingState } from 'material-react-table'
import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SwipeableDrawer,
  useTheme,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Divider,
} from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getApiV2 } from '../../libs/api'
import { AuthContext } from '../../contexts/authContext'
import { useParams } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import {
  AccountTree,
  Battery20,
  Battery50,
  Delete,
  LinkedIn,
  NotificationsPaused,
  Pause,
  Search,
  Upgrade,
} from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { ReplaceInput } from '../../components/ReplaceInput'
import { UpdatePosition } from '../../components/UpdatePosition'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.1),
    border: 0,
    '&.MuiButtonBase-root': {
      padding: '4px',
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))

const FIELDS = [
  {
    label: 'First name',
    field: 'first_name',
    api: 'firstName',
  },
  {
    label: 'Last name',
    field: 'last_name',
    api: 'lastName'
  },
  {
    label: 'Email',
    field: 'email',
    api: 'email'
  },
  {
    label: 'Title',
    field: 'title',
    api: 'title'
  },
  {
    label: 'Company',
    field: 'company',
    api: 'company'
  },
]

interface ConfirmationDialogProps {
  open: boolean
  onSave: () => void
  onClose: () => void
  isSaving: boolean
  contacts: any[]
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const ignore = props.contacts.filter((m) => m.action === 'ignore').length
  const remove = props.contacts.filter((m) => m.action === 'remove').length
  const update = props.contacts.filter((m) => m.action === 'update').length
  const updateWithCompany = props.contacts.filter((c) => c.action === 'update' && c.company !== undefined).length

  return (
    <Dialog open={props.open} maxWidth="sm">
      <DialogTitle>Confirm changes</DialogTitle>
      <DialogContent>
        {ignore > 0 && (
          <Typography sx={{ mb: 1 }}>
            Adding{' '}
            <Typography sx={{ pl: 1, pr: 1, backgroundColor: 'black', color: 'white', display: 'inline' }}>
              {ignore}
            </Typography>{' '}
            contacts to ignore list. These contacts will be hidden from from updater until they are next modified in
            HubSpot. HubSpot data will not be modified for these.
          </Typography>
        )}
        {remove > 0 && (
          <Typography sx={{ mb: 1 }}>
            Archiving{' '}
            <Typography sx={{ pl: 1, pr: 1, backgroundColor: 'black', color: 'white', display: 'inline' }}>
              {remove}
            </Typography>{' '}
            contacts. If necessary, you will be able to restore these in HubSpot during the next 30 days.
          </Typography>
        )}
        {update > 0 && (
          <Typography sx={{ mb: 1 }}>
            Updating properties for{' '}
            <Typography sx={{ pl: 1, pr: 1, backgroundColor: 'black', color: 'white', display: 'inline' }}>
              {update}
            </Typography>{' '}
            contacts. These contacts will also be profiled again with updated data.
          </Typography>
        )}
        {updateWithCompany > 0 && (
          <Typography sx={{ mb: 1 }}>
            Company data have been set to update for{' '}
            <Typography sx={{ pl: 1, pr: 1, backgroundColor: 'black', color: 'white', display: 'inline' }}>
              {updateWithCompany}
            </Typography>{' '}
            contacts. For these, current primary company association will be removed. We will look up for newly defined
            company, create it if necessary, and create a new primary association from contact to company.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <LoadingButton variant="contained" loading={props.isSaving} onClick={props.onSave}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

interface DetailsDrawerProps {
  open: boolean
  contact: any
  onClose: () => void
}

const DetailsDrawer = (props: DetailsDrawerProps) => {
  const [contact, setContact] = React.useState<any>({})

  React.useEffect(() => {
    setContact(props.contact)
  }, [props.contact])
  return (
    <SwipeableDrawer anchor="right" open={props.open} onClose={props.onClose} onOpen={() => 1}>
      {props.contact && (
        <Box sx={{ m: 3, width: '500px' }}>
          <Typography sx={{ fontSize: '24px' }}>
            {contact.first_name} {contact.last_name}
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Link target="_blank" href={contact['hubspot_url'] as string}>
              HubSpot
            </Link>{' '}
            <Link target="_blank" href={contact['linkedin_url'] as string}>
              LinkedIn
            </Link>
          </Box>
        </Box>
      )}
    </SwipeableDrawer>
  )
}

export const IntegrationContacts = () => {
  const auth = useContext(AuthContext)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false)
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = React.useState(false)
  const [selectedContact, setSelectedContact] = React.useState({})
  const [contacts, setContacts] = React.useState<any[]>([])
  const [search, setSearch] = React.useState({ age: '0', status: 'MISSING_DATA' })
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnFilters] = React.useState<MRT_ColumnFiltersState>([])
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])

  const { id } = useParams()

  const reset = () => {
    setContacts(data?.contacts ?? [])
  }

  const getContactModifications = () => {
    return contacts
      .map((c: any, index) => ({
        action: c.action,
        id: c.id,
        ...Object.fromEntries(
          FIELDS.filter((f) => c[f.field] !== data?.contacts?.[index]?.[f.field]).map((f) => [f.api, c[f.field]])
        ),
      }))
      .filter(
        (c: any, index) =>
          ['ignore', 'remove'].includes(c.action) ||
          (c.action === 'update' &&
            FIELDS.map((f) => f.field).some((f: string) => {
              return c[f] !== data?.contacts[index]?.[f]
            }))
      )
  }

  const fetchContacts = async () => {
    return await (
      await getApiV2(auth)
    ).get(`/integrations/${id}/contacts`, {
      params: {
        search: JSON.stringify(search),
        filters: JSON.stringify(columnFilters ?? []),
        sorting: JSON.stringify(sorting ?? []),
      },
    })
  }

  const replaceInputCell = (row: any, key: string) => {
    return (
      <ReplaceInput
        value={row.original[key]}
        setValue={(value) => updateContact(row.index, { [key]: value, action: 'update' })}
        isModified={![undefined, null, data?.contacts?.[row.index]?.[key] ?? ''].includes(contacts[row.index]?.[key])}
        replaceOptions={[contacts[row.index]?.suggestions?.[key]].filter((v) => v != null)}
        errorCount={row.original.problems.filter((problem: any) => problem.field === key).length}
      />
    )
  }

  const contactColumns = [
    {
      header: 'First name',
      accessorKey: 'first_name',
      Cell: ({ row }: any) => replaceInputCell(row, 'first_name'),
    },
    {
      header: 'Last name',
      accessorKey: 'last_name',
      Cell: ({ row }: any) => replaceInputCell(row, 'last_name'),
    },
    {
      header: 'Email',
      accessorKey: 'email',
      Cell: ({ row }: any) => replaceInputCell(row, 'email'),
    },
    {
      header: 'Title',
      accessorKey: 'title',
      Cell: ({ row }: any) => replaceInputCell(row, 'title'),
    },
    {
      header: 'Company',
      accessorKey: 'company',
      Cell: ({ row }: any) => (
        <UpdatePosition
          company={row.original.company}
          setPosition={(position) => {
            updateContact(row.index, {
              company: position.company,
              title: position.title,
              ...(position.email ? { email: position.email } : {}),
              action: 'update',
            })
          }}
          isModified={
            ![undefined, null, data?.contacts?.[row.index]?.company ?? ''].includes(contacts[row.index]?.company)
          }
          suggestedPositions={
            row.original.suggestions?.positions?.filter(
              (position: any) =>
                position.company !== row.original.companies?.[0] ||
                position.email !== row.original.email ||
                position.title !== row.original.title
            ) ?? []
          }
          errorCount={0}
        />
      ),
    },
  ]

  const { isLoading: isSaving, mutate: save } = useMutation(
    async () => {
      await (
        await getApiV2(auth)
      ).patch(`/integrations/${id}/contacts`, {
        contacts: getContactModifications(),
      })
    },
    {
      onSuccess: (retval: any) => {
        enqueueSnackbar('Changes successfully queued', { variant: 'success' })
        refetch()
        setIsConfirmationDialogOpen(false)
      },
      onError: () => {
        enqueueSnackbar('Save failed!', { variant: 'error' })
        setIsConfirmationDialogOpen(false)
      },
    }
  )

  const { mutate: update } = useMutation(
    async (editData: any) => {
      return editData
    },
    {
      onSuccess: (retval: any) => {
        const editIndex = contacts.findIndex((c) => c.id === retval.id)
        const newContacts = [...contacts]

        for (const field of retval.fields) {
          newContacts[editIndex] = {
            ...newContacts[editIndex],
            action: 'update',
            [field.field]: field.value,
          }
        }

        setContacts(newContacts)
        console.log(newContacts)
      },
    }
  )

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: [
      'contacts',
      columnFilters, //refetch when columnFilters changes
      search,
      sorting, //refetch when sorting changes
    ],

    queryFn: async () => {
      const response = await fetchContacts()

      console.log(response.data.contacts)
      return {
        meta: response.data.meta,
        contacts: response.data.contacts.map((contact: any) => ({
          ...contact,
          suggestions: {
            positions: contact?.suggestions?.positions ?? []
          },
          email: contact.email || '',
          action: 'skip',
        })),
      }
    },
    keepPreviousData: false,
    retry: 0,
  })

  React.useEffect(() => {
    setContacts(data?.contacts ?? [])
  }, [data])

  const theme = useTheme()

  const updateContact = (index: number, newData: any) => {
    if (newData.action === null) {
      newData.action = contacts[index].action
    }

    const newContacts = [...contacts]
    newContacts[index] = { ...newContacts[index], ...newData }
    setContacts(newContacts)
  }

  return (
    <>
      <Box sx={{ height: '80vh', width: '100%', mb: 30 }}>
        <MaterialReactTable
          muiTopToolbarProps={{ sx: { zIndex: 0 } }}
          muiTableHeadProps={{ sx: { zIndex: 0 } }}
          data={contacts}
          initialState={{ density: 'compact' }}
          enableDensityToggle={false}
          columns={contactColumns}
          enableRowVirtualization={true}
          enableBottomToolbar={false}
          enablePagination={false}
          enableMultiRowSelection={true}
          enableColumnResizing={true}
          enableGlobalFilter={false}
          enableSorting={false}
          enablePinning={true}
          enableColumnFilters={false}
          // enableEditing={true} -- using custom edit mode
          // editingMode="cell" -- using custom edit mode
          //manualFiltering
          manualSorting
          rowCount={data?.meta?.total_rows ?? 0}
          //onColumnFiltersChange={setColumnFilters}
          onSortingChange={setSorting}
          onRowSelectionChange={setRowSelection}
          muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            onChange: (event) => {
              update({
                id: cell.row.original.id,
                fields: [
                  {
                    field: cell.column.id,
                    value: event.target.value,
                  },
                ],
              })
            },
          })}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Action', //change header text
              size: 230, //make actions column wider
            },
          }}
          enableRowActions
          renderRowActions={({ row }) => (
            <>
              <StyledToggleButtonGroup
                color="primary"
                size="small"
                exclusive={true}
                value={row.original.action}
                onChange={(event: React.MouseEvent<HTMLElement>, newAction: string) =>
                  updateContact(row.index, { action: newAction })
                }>
                <ToggleButton value="skip" key="s">
                  <Tooltip title="Skip (no action now)">
                    <Pause />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="update" key="u">
                  <Tooltip title="Update to HubSpot">
                    <Upgrade />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="ignore" key="i">
                  <Tooltip title="Ignore and hide (no changes to HubSpot)">
                    <NotificationsPaused />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="remove" key="d">
                  <Tooltip title="Delete from HubSpot">
                    <Delete />
                  </Tooltip>
                </ToggleButton>
              </StyledToggleButtonGroup>

              {false && (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{
                    pl: 0,
                    pr: 0,
                    ml: 2,
                    mr: 1,
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    ':hover': { color: theme.palette.secondary.main, borderColor: theme.palette.secondary.main },
                  }}
                  onClick={() => {
                    setSelectedContact(row.original)
                    setIsDetailsDialogOpen(true)
                  }}
                  startIcon={<Search />}>
                  {(row.original.suggestions && Object.keys(row.original.suggestions).length) ?? 0}
                </Button>
              )}

              <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} />

              {row.original.linkedin_url && (
                <Link href={row.original.linkedin_url} target="_blank" sx={{ mt: 0.8 }}>
                  <LinkedIn />
                </Link>
              )}
              {row.original.hubspot_url && (
                <Link href={row.original.hubspot_url} target="_blank">
                  HubSpot
                </Link>
              )}

              <Divider orientation="vertical" sx={{ ml: 2, mr: 2 }} />

              {[
                {
                  type: 'duplicate',
                  description: 'Potentially duplicate',
                  icon: <AccountTree color="warning" fontSize="small" />,
                },
                {
                  type: 'old',
                  description: 'No activities or modifications in 1 year',
                  icon: <Battery50 fontSize="small" color="warning" />,
                },
                {
                  type: 'veryold',
                  description: 'No activities or modifications in 2 years',
                  icon: <Battery20 fontSize="small" color="warning" />,
                },
              ]
                .filter((problem: any) => row.original.problems?.find((p: any) => p.type === problem.type))
                .map((problem: any) => (
                  <Tooltip title={problem.description}>{problem.icon}</Tooltip>
                ))}
            </>
          )}
          state={{
            columnFilters,
            isLoading,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
            rowSelection,
          }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box>
              <Button variant="text" sx={{ mb: 3 }} onClick={reset}>
                Forget all changes
              </Button>
              <Button variant="text" sx={{ mb: 3, ml: 1 }} onClick={() => refetch()}>
                Refresh
              </Button>

              <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <FormControl sx={{ width: 200 }}>
                  <InputLabel>Search</InputLabel>
                  <Select
                    label="Find with status"
                    color="secondary"
                    value={search.status}
                    onChange={(event: SelectChangeEvent) =>
                      setSearch({ ...search, status: event.target.value as string })
                    }>
                    <MenuItem sx={{ pl: 1 }} disabled>
                      By status
                    </MenuItem>
                    <MenuItem value="NOT_FOUND">No matching public profiles</MenuItem>
                    <MenuItem value="MISSING_DATA">HubSpot data insufficient</MenuItem>
                    <MenuItem value="OUT_OF_DATE">Position changed</MenuItem>
                    <MenuItem value="UP_TO_DATE">Up to date</MenuItem>
                    <MenuItem disabled></MenuItem>
                    <MenuItem sx={{ pl: 1 }} disabled>
                      By function
                    </MenuItem>
                    <MenuItem value="DUPLICATES">Duplicates</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
        />

        <Drawer anchor="bottom" open={true} variant="permanent">
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
            <Typography sx={{ mr: 2 }}>
              {['update', 'remove', 'skip', 'ignore']
                .map((action) => ({
                  amount: contacts.filter((c: any) => c.action === action).length,
                  action,
                }))
                .filter((c) => c.amount > 0)
                .map((c) => `${c.amount} ${c.action}`)
                .join(', ')}
            </Typography>
            <Button
              disabled={contacts.filter((c) => c.action !== 'skip').length === 0}
              color="primary"
              variant="contained"
              onClick={() => setIsConfirmationDialogOpen(true)}>
              Save
            </Button>
          </Box>
        </Drawer>

        <ConfirmationDialog
          contacts={getContactModifications()}
          isSaving={isSaving}
          onClose={() => setIsConfirmationDialogOpen(false)}
          onSave={() => save()}
          open={isConfirmationDialogOpen}
        />

        <DetailsDrawer
          contact={selectedContact}
          open={isDetailsDialogOpen}
          onClose={() => setIsDetailsDialogOpen(false)}
        />
      </Box>
    </>
  )
}
