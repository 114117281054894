import { Typography } from '@mui/material';
import { ContactPosition } from './ContactPosition';
import { ContactPositionSkeleton } from './ContactPositionSkeleton';

interface CopyPositionProps {
  company: string;
  title: string;
  email: string;
}

interface ContactPositionsProps {
  analysis: any;
  onUseCompany?: (contactData: CopyPositionProps) => void;
  isLoading: boolean;
  isEmailsLoading: boolean;
  data?: any;
}

export const ContactPositions = (props: ContactPositionsProps) => {
  return (
    <>
      <Typography variant="h6">Current positions</Typography>

      {props.isLoading ? (
        <>
          <ContactPositionSkeleton showOnUse={true} />
          <ContactPositionSkeleton showOnUse={true} />
        </>
      ) : (
        (props.analysis.experience?.positions ?? [])
          .filter((company: any) => company.positions.some((position: any) => position.end_at === null))
          .map((company: any) => (
            <ContactPosition
              company={company}
              data={props.data}
              onUse={props.onUseCompany ? (company) =>
                props.onUseCompany!({
                  company: company.company,
                  title: company.positions[0].title,
                  email: company.email ?? ''
                }) : undefined
              }
              isEmailLoading={props.isEmailsLoading}
            />
          ))
      )}

      {!props.isLoading &&
        props.analysis?.experience?.positions.filter((company: any) =>
          company.positions.some((position: any) => position.end_at === null)
        )?.length === 0 && (
          <Typography sx={{ mt: 2 }} variant="body1">
            No known current positions
          </Typography>
        )}

      <Typography sx={{ mt: 2 }} variant="h6">
        Previous positions
      </Typography>

      {props.isLoading ? (
        <>
          <ContactPositionSkeleton showOnUse={false} />
          <ContactPositionSkeleton showOnUse={false} />
        </>
      ) : (
        (props.analysis.experience?.positions ?? [])
          .filter((company: any) => company.positions.every((position: any) => position.end_at != null))
          .map((company: any) => <ContactPosition company={company} />)
      )}

      {!props.isLoading &&
        props.analysis.experience?.positions.filter((company: any) =>
          company.positions.every((position: any) => position.end_at != null)
        )?.length === 0 && (
          <Typography sx={{ mt: 2 }} variant="body1">
            No known previous positions
          </Typography>
        )}

      <Typography sx={{ mt: 2 }} variant="h6">
        Volunteer positions
      </Typography>

      {props.isLoading ? (
        <ContactPositionSkeleton showOnUse={false} />
      ) : (
        (props.analysis.volunteerExperience?.positions ?? []).map((company: any) => (
          <ContactPosition company={company} />
        ))
      )}

      {!props.isLoading && props.analysis.volunteerExperience?.positions?.length === 0 && (
        <Typography sx={{ mt: 2 }} variant="body1">
          No known volunteer positions
        </Typography>
      )}
    </>
  );
};
