import React from 'react'
import { Button, Grid } from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { LoadingButton } from '@mui/lab'

interface SignInFormProps {
  onSubmit: (email: string, password: string) => void
}

export const SignInForm = (props: SignInFormProps) => {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
  })

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const formRef = React.useRef<ValidatorForm>(null)
  const [isFormValid, setIsFormValid] = React.useState(false)

  React.useEffect(() => {
    const validate = async () => {
      if (formRef !== null && formRef.current !== null) {
        setIsFormValid(await formRef.current.isFormValid(true))
      }
    }

    validate()
  }, [values])

  const handleChange = (event: any) => {
    const { name, value, type } = event.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await props.onSubmit(values.email, values.password)
    setIsSubmitting(false)
  }

  return (
    <ValidatorForm
      ref={formRef}
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      sx={{
        borderWidth: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}>
      <Grid container gap={1}>
        <Grid item xs={12}>
          <TextValidator
            label="Email"
            onChange={handleChange}
            name="email"
            validators={['required']}
            errorMessages={['Email is required']}
            value={values.email}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            label="Password"
            name="password"
            id="password"
            type="password"
            validators={['required']}
            errorMessages={['Password is required']}
            onChange={handleChange}
            value={values.password}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button fullWidth type="submit" disabled={!isFormValid} variant="contained">
            Sign in
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}
