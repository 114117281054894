import { Box, Divider, Button, Breadcrumbs, Typography } from '@mui/material';
import React from 'react'
import { AuthContext } from '../../../contexts/authContext'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { enqueueSnackbar } from 'notistack';
import { getApi } from '../../../libs/api';
import { DateTime } from 'luxon';

export const SuperAdminCustomerList = () => {
  const nav = useNavigate();

  const auth = React.useContext(AuthContext)

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [items, setItems] = React.useState([]);
  
  const headers = [
    {
      label: 'Created at',
      field: 'createdAt',
    },
    {
      label: 'Company',
      field: 'name'
    },
    {
      label: 'Actions',
      field: 'id',
      render: (id: string) => (
        <Button variant="outlined" onClick={() => nav(`/admin/customers/${id}`)}>View</Button>
      )
    }
  ]

  const fetchData = async () => {
    setIsRefreshing(true);

    try {
      const response = await (await getApi(auth)).get('/admin/customers')

      setItems(response.data.map((customer: any) => ({
          id: customer.id,
          createdAt: DateTime.fromSQL(customer.created_at).toLocaleString({...DateTime.DATETIME_SHORT, hour: '2-digit'}),
          name: customer.name
        })))
    } catch (err) {
      enqueueSnackbar('Update failed. Try refreshing the page.', { variant: 'error' })
      console.log('Failed to get data ' + err)
    }

    setIsRefreshing(false);

    return [];
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{m: 1}}>
          <Breadcrumbs>
            <Typography color="text.primary">SuperAdmin</Typography>
            <Typography color="text.primary">Customers</Typography>
          </Breadcrumbs>
        </Box>
      </div>

      <Divider sx={{mb:2, mt:2}} />

      <DataTable items={items} isLoading={isRefreshing} headers={headers} />
    </>
  )
}
