import { IAuth } from "../contexts/authContext";
import axios from 'axios';

export const getApi = async (auth: IAuth) => {
    const sessionInfo = await auth.getSessionInfo();

    return axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        Authorization: `Bearer ${sessionInfo.accessToken}`,
      },
      timeout: 60 * 1000,
    })
  }

  export const getApiV2 = async (auth: IAuth) => {
    const sessionInfo = await auth.getSessionInfo();

    const instance = axios.create({
      baseURL: import.meta.env.VITE_API_V2_URL,
      headers: {
        Authorization: `Bearer ${sessionInfo.accessToken}`,
      },
      timeout: 60 * 1000,
    })

    instance.interceptors.response.use((response) => {
      if (response?.data?.user?.credits) {
        auth.setCreditsLeft!(response.data.user.credits);
      }

      return response;
    });

    return instance;
  }