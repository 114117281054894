import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { LoadingButton } from '@mui/lab'

interface ForgotPasswordFormProps {
  onSubmit: (email: string) => void
}

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const [values, setValues] = React.useState({
    email: '',
  })

  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const formRef = React.useRef<ValidatorForm>(null)
  const [isFormValid, setIsFormValid] = React.useState(false)

  React.useEffect(() => {
    const validate = async () => {
      if (formRef !== null && formRef.current !== null) {
        setIsFormValid(await formRef.current.isFormValid(true))
      }
    }

    validate()
  }, [values])

  const handleChange = (event: any) => {
    const { name, value, type } = event.target

    setValues({
      ...values,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    props.onSubmit(values.email)
    setIsSubmitting(false)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={(errors) => console.log(errors)}>
        <TextValidator
          label="Email"
          onChange={handleChange}
          name="email"
          validators={['required']}
          errorMessages={['Email is required']}
          value={values.email}
          fullWidth
        />

        <LoadingButton
          fullWidth
          sx={{ mt: 1 }}
          loading={isSubmitting}
          type="submit"
          disabled={!isFormValid}
          variant="contained">
          Restore password
        </LoadingButton>
      </ValidatorForm>
    </Box>
  )
}
