export const formatDuration = (from: string, to: string) => {
    var timeline = '';

    const fromDate = new Date(from);
    const toDate = new Date(to ?? Date.now());

    if (!to) {
      timeline = `${fromDate.getMonth() + 1}/${fromDate.getFullYear()} - `;
    } else {
      timeline = `${fromDate.getMonth() + 1}/${fromDate.getFullYear()} - ${
        toDate.getMonth() + 1
      }/${toDate.getFullYear()}`;
    }

    const months = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());

    return timeline + ` • ${Math.floor(months / 12)} years ${months % 12} months`;
  };