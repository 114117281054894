import { Box, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, CircularProgress } from '@mui/material';

interface DataTableHeader {
    label: string,
    field: string,
    render?: (data:any) => JSX.Element;
}

interface DataTableItem {
    id: string;
    [x: string | number | symbol]: unknown;
}

interface DataTableProps {
    isLoading: boolean;
    headers: DataTableHeader[];
    items: DataTableItem[];
}

export const DataTable = (props:DataTableProps) => {
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow key='head' sx={{fontSize: 10, backgroundColor: 'red'}}>
              {props.headers.map((column, index) => (
                <TableCell
                sx={{backgroundColor: '#eee'}}
                  key={`header_${index}`}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items
              .map((row:any, rowIndex:number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`1_${rowIndex}`}>
                    {props.headers.map((header, columnIndex:number) => {
                      const value = Array.isArray(row) ? row[columnIndex] : row[header.field];
                      const renderedValue = header.render !== undefined ? header.render(value) : value;
                      return (
                        <TableCell key={`${rowIndex}_${columnIndex}`}>
                          {renderedValue}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {props.isLoading &&
            <Box sx={{width: '100%', display:'flex', alignItems: 'center', justifyContent:'center'}}>
                <CircularProgress sx={{m:5}}/>
            </Box>
        }
        {(!props.isLoading && props.items.length === 0) &&
            <Box sx={{width: '100%', display:'flex', alignItems: 'center', justifyContent:'center'}}>
              <Typography sx={{m: 5}}>No data</Typography>
            </Box>
        }
      </TableContainer>
      </Paper>
    </>
  )
}
