import { Female, Male } from '@mui/icons-material';
import { Box, Divider, Skeleton, Tooltip, Typography, TypographyProps, styled } from '@mui/material';

const PropTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '14px',
  mt: 1,
  color: 'rgba(0, 0, 0, 0.6)'
}));

const PropValue = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '16px'
}));

interface ContactDemographicsProps {
  contact: any;
  isLoading: boolean;
}

export const ContactDemographics = (props: ContactDemographicsProps) => (
  <>
    <Typography variant="h6">Demographics</Typography>

    <>
      <Tooltip title="Contact's location based on the digital footprint" placement="left">
        <Box>
          <PropTitle>Location</PropTitle>
          <PropValue>
            {props.isLoading ? (
              <Skeleton width={30} />
            ) : (
              <>
                {props.contact.location.city} {props.contact.location.country}
              </>
            )}
          </PropValue>
        </Box>
      </Tooltip>

      <Tooltip title="Contact's language skills based on the digital footprint" placement="left">
        <Box>
          <PropTitle sx={{ mt: 1, fontSize: '14px' }}>Languages</PropTitle>
          <PropValue variant="body1">
            {props.isLoading ? (
              <Skeleton width={200} />
            ) : (
              <>{props.contact.languages?.length > 0 ? props.contact.languages.join(', ') : 'Unknown'}</>
            )}
          </PropValue>
        </Box>
      </Tooltip>

      <Divider sx={{ mt: 3, mb: 2 }} />

      <Tooltip title="Contact's highest education degree based on the digital footprint" placement="left">
        <Box>
          <PropTitle sx={{ mt: 1, fontSize: '14px' }}>Degree</PropTitle>
          <PropValue variant="body1">
            {props.isLoading ? <Skeleton width={250} /> : <>{props.contact.education.degree ?? 'Unknown'}</>}
          </PropValue>
        </Box>
      </Tooltip>

      <Tooltip
        title="Contact's graduation school (highest degree) based on the digital footprint"
        placement="left"
      >
        <Box>
          <PropTitle sx={{ mt: 1, fontSize: '14px' }}>School</PropTitle>
          <PropValue variant="body1">
            {props.isLoading ? <Skeleton width={200} /> : <>{props.contact.education.school ?? 'Unknown'}</>}
          </PropValue>
        </Box>
      </Tooltip>

      <Tooltip
        title="Contact's education level based on the ISCED 11 framework: 
https://en.wikipedia.org/wiki/International_Standard_Classification_of_Education#ISCED_2011_levels,_categories,_and_sub-categories"
        placement="left"
      >
        <Box>
          <PropTitle sx={{ mt: 1, fontSize: '14px' }}>Education level (ISCED11)</PropTitle>
          <PropValue variant="body1">
            {props.isLoading ? <Skeleton width={80} /> : <>{props.contact.education.ISCED11}</>}
          </PropValue>
        </Box>
      </Tooltip>

      <Divider sx={{ mt: 3, mb: 2 }} />

      <Tooltip title="Gender based on the first name classification and image analysis" placement="left">
        <Box>
          <PropTitle>Gender</PropTitle>
          <Box sx={{ display: 'flex' }}>
            {props.isLoading ? (
              <>
                <Skeleton variant="rectangular" width={24} height={24} />
                <Skeleton variant="rectangular" width={40} height={24} sx={{ ml: 0.5 }} />
              </>
            ) : (
              <>
                {props.contact.gender === 'M' && (
                  <>
                    <Male sx={{ color: 'blue', lineHeight: '24px' }} />
                    <Typography sx={{ pl: 1, lineHeight: '24px' }} variant="body1">
                      {' '}
                      Male
                    </Typography>
                  </>
                )}
                {props.contact.gender === 'F' && (
                  <>
                    <Female sx={{ color: 'red', lineHeight: '24px' }} />
                    <Typography sx={{ pl: 1, lineHeight: '24px' }} variant="body1">
                      {' '}
                      Female
                    </Typography>
                  </>
                )}
                {!props.contact.gender && 'Unknown'}
              </>
            )}
          </Box>
        </Box>
      </Tooltip>

      <Tooltip
        title="Contact's age estimate based on their digital footprint. Allow 1-2 year variation."
        placement="left"
      >
        <Box>
          <PropTitle>Age estimate</PropTitle>
          <PropValue variant="body1">
            {props.isLoading ? (
              <Skeleton width={70} />
            ) : (
              <>
                {props.contact.age.age ?? 'Unknown'} (Birth year {props.contact.age.birthYear ?? 'Unknown'})
              </>
            )}
          </PropValue>
        </Box>
      </Tooltip>
    </>
  </>
);
