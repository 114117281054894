import { Box, Divider, Breadcrumbs, Link, Typography, FormGroup, FormControlLabel, Checkbox, Alert, AlertTitle } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import { getApiV2 } from '../../libs/api'
import { enqueueSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { useMutation } from '@tanstack/react-query'

export const IntegrationCreate = () => {
  const auth = React.useContext(AuthContext)
  const [approved, setApproved] = React.useState(false)

  const { mutate: createAuthorizationUrl, isLoading } = useMutation(
    async () => {
      return await (
        await getApiV2(auth)
      ).post('/integration-authorizations', {
        type: 'HUBSPOT',
      })
    },
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          localStorage.removeItem('callbackIntegrationId')
          window.location = response.data.authorization_url
        } else {
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
        }
      },
    }
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApproved(event.target.checked)
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/integrations">
            Integrations
          </Link>
          <Typography color="text.primary">Create integration</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Alert severity="info">
        <AlertTitle>Get started with free plan</AlertTitle>
        <p>
          Opening Traitspotter via Traitspotter button under contact, company or
          deal will fetch, build and display the data on demand.
        </p>
        <p>
          Paid plans come with automatic data synchronization. Data is available immediately with no fetch delay. Paid
          plan also includes key information right inside HubSpot view and enables pushing contact data in searchable form
          directly to HubSpot. This allows e.g. searching contacts with Traitspotter
          data (e.g. conctact lists for all extroverted contact or certain education level or age between given limits).
        </p>
      </Alert>

      <FormGroup sx={{ mb: 2 }}>
        <Box sx={{ mb: 0, mt: 1 }}>
          <Link href="https://traitspotter.com/traitspotter-hubspot-app-data-usage-description/" target="_blank">
            Read the document
          </Link>{' '}
          (opens in new window/tab)
        </Box>
        <FormControlLabel
          sx={{ pt: 0 }}
          control={<Checkbox value={approved} id="hubspot_app_data_usage_description" onChange={handleChange} />}
          label={`I have read and agree to HubSpot app data usage description`}
        />
      </FormGroup>

      <LoadingButton
        loading={isLoading}
        variant="contained"
        disabled={!approved}
        onClick={() => {
          createAuthorizationUrl()
        }}>
        Connect to HubSpot
      </LoadingButton>
    </>
  )
}
