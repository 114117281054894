import { LoadingButton } from '@mui/lab'
import { Checkbox, FormGroup, FormControlLabel, Link, Box, Breadcrumbs, Typography, Divider } from '@mui/material'
import React from 'react'
import { AuthContext } from '../contexts/authContext'
import { getApi, getApiV2 } from '../libs/api'
import { useNavigate } from 'react-router-dom'

interface Document {
  id: string
  version: string
}

export const TermsAndConditions = () => {
  const auth = React.useContext(AuthContext)
  const nav = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [acceptedDocuments, setAcceptedDocuments] = React.useState<Document[]>([])

  const onSubmit = async () => {
    setLoading(true);
    await (await getApiV2(auth)).patch('/user', {accepted_documents: acceptedDocuments});
    await auth.updateSessionInfo();
    setLoading(false);

    nav('/integrations');
  }

  const handleChange = (e: any) => {
    if (!e.target.checked) {
        setAcceptedDocuments(acceptedDocuments.filter((document) => document.id !== e.target.id))
    } else {
        setAcceptedDocuments([
        ...acceptedDocuments,
        {
          id: e.target.id,
          version: auth.userInfo!.pendingDocuments!.find((document) => document.id === e.target.id)!.latestVersion,
        },
      ])
    }
  }

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs>
          <Typography color="text.primary">Please read and approve the following documents to proceed</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      {auth.userInfo?.pendingDocuments?.map((document) => (
        <>
          <Typography variant="h6">
            {document.name} ({document.latestVersion})
          </Typography>
          <FormGroup sx={{ ml: 2 }}>
            <Box sx={{ mb: 0, mt: 1 }}>
              <Link href={document.link} target="_blank">
                Read the document
              </Link>{' '}
              (opens in new window/tab)
            </Box>
            <FormControlLabel
              sx={{ pt: 0 }}
              control={<Checkbox id={document.id} onChange={handleChange} />}
              label={`I have read and agree to ${document.name}`}
            />
          </FormGroup>
        </>
      ))}

      <LoadingButton loading={loading} sx={{ mt: 2 }} onClick={onSubmit} variant="contained">
        Submit
      </LoadingButton>
    </>
  )
}
