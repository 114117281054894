import { Link, Box, Divider, Paper, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Chip, Breadcrumbs, Typography, CircularProgress, Tabs, Tab } from '@mui/material';
import { Sync, Help, Error, Create } from '@mui/icons-material'
import React from 'react'
import axios from 'axios'
import { AuthContext } from '../../../contexts/authContext'
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { NavLink } from 'react-router-dom';
import { getApiV2 } from '../../../libs/api';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

export const SuperAdminIntegrationView = () => {
  const { id } = useParams();

  const auth = React.useContext(AuthContext)

  const [integration, setIntegration] = React.useState({name: 'unknown', status:'', type:'', authorization_url: ''});
  const [activating, setActivating] = React.useState(false);

  const fetchData = async () => {
    try {      
      const response = await (await getApiV2(auth)).get(`/admin/integrations/${id}`)

      setIntegration(response.data)
    } catch (err) {
      console.log('Failed to get data ' + err)
    }
  }

  const activate = async () => {
    setActivating(true);

    try {
      await (await getApiV2(auth)).patch(`/admin/integrations/${id}`, {
        action: 'ACTIVATE'
      });
      enqueueSnackbar('Integration activated', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar('Activation failed', { variant: 'error' });
    }

    await fetchData();
    setActivating(false);
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const getStatus = () => {
    switch (integration.status) {
      case 'CREATED': return 'not connected';
      case 'CONNECTED': return 'pending approval';
      case 'ACTIVE': return 'active';
      case 'PAUSED': return 'paused';
      case 'DELETED': return 'deleted';
    }
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{m: 1}}>
          <Breadcrumbs>
            <Typography color="text.primary">SuperAdmin</Typography>
            <NavLink to="/admin/integrations">Integrations</NavLink>
            <Typography color="text.primary">{integration.name}</Typography>
          </Breadcrumbs>
        </Box>
      </div>

      <Divider sx={{mb:2, mt:2}} />

      <Box>
        <Typography color="text.secondary">Name</Typography>
        <Typography gutterBottom>{integration.name}</Typography>

        <Typography color="text.secondary">Type</Typography>
        <Chip sx={{mb: 1}} color="info" size="small" label={integration.type} />

        <Typography color="text.secondary">Status</Typography>
        <Chip sx={{mb: 1}}color="info" size="small" label={getStatus()} />

        {integration.status === 'CONNECTED' &&
          <>
            <p>Activating integration starts immediately profiling data.</p>
            <LoadingButton variant="contained" onClick={activate} loadingPosition="start" loading={activating}>Activate</LoadingButton>
          </>
        }
      </Box>
    </>
  )
}
