'use client'

import React, { useContext } from 'react'
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { HashRouter as Router, Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom'

import AuthProvider, { AuthContext, IAuth, AuthStatus } from './contexts/authContext'
import { SnackbarProvider } from 'notistack'

import { ForgotPassword } from './routes/auth/ForgotPassword'
import { ApiKeys } from './routes/ApiKeys'
import { BatchList } from './routes/batches/BatchList'
import { BatchAnalysisCreate } from './routes/batches/BatchAnalysisCreate'
import { BatchView } from './routes/batches/BatchView'
import { TaskCreate } from './routes/tasks/TaskCreate'
import { BatchSearchCreate } from './routes/batches/BatchSearchCreate'
import { IntegrationList } from './routes/integrations/IntegrationList'
import { IntegrationCreate } from './routes/integrations/IntegrationCreate'
import { IntegrationSettings } from './routes/integrations/IntegrationSettings'
import { IntegrationCallback } from './routes/integrations/IntegrationCallback'
import { TermsAndConditions } from './routes/TermsAndConditions'
import { SignUp } from './routes/auth/SignUp'
import { SignIn } from './routes/auth/SignIn'
import TagManager from 'react-gtm-module'
import { Integration } from './routes/integrations/Integration'
import { NarrowLayout } from './layouts/NarrowLayout'
import { NormalLayout } from './layouts/NormalLayout'
import { IntegrationContacts } from './routes/integrations/IntegrationContacts'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SuperAdminIntegrationList } from './routes/superadmin/integrations/SuperAdminIntegrationList'
import { SuperAdminIntegrationView } from './routes/superadmin/integrations/SuperAdminIntegrationView'
import { SuperAdminCustomerList } from './routes/superadmin/customers/SuperAdminCustomerList'
import { SuperAdminCustomerView } from './routes/superadmin/customers/SuperAdminCustomerView'
import { AutoLogin } from './routes/auth/AutoLogin'
import { Analysis } from './routes/analysis/Analysis'
import { CompanyFinder } from './routes/analysis/CompanyFinder'
import { AdminRolesList } from './routes/admin/roles/AdminRolesList'
import { Preferences } from './routes/Preferences'

const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: '#262262',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F24389',
    },
    success: {
      main: '#50aa50',
    },
    info: {
      main: '#009cbc',
    },
    error: {
      main: '#ff7777',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '32px',
        },
        h2: {
          fontSize: '24px',
        },
        h3: {
          fontSize: '20px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#fff',
          backgroundColor: '#F24389',
          '&:hover': {
            backgroundColor: '#262262',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: '#262262',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#262262',
          color: '#fff',
        },
      },
    },
  },
})

TagManager.initialize({
  gtmId: 'GTM-W3PJRNV',
  auth: process.env.NODE_ENV === 'development' ? 'tWJh87vWZDmL42ZnbfqWhQ' : '1o-sE1H7jLo6IUANkkSP4A',
  preview: process.env.NODE_ENV === 'development' ? 'env-21' : 'env-1',
  dataLayer: {
    appSource: 'panel',
  },
})

const MainRoute: React.FunctionComponent = () => {
  type ProtectedRouteProps = {
    authenticationPath?: string
    group?: string
    authenticated?: boolean
    outlet: JSX.Element
  }

  const ProtectedRoute = ({ group, authenticated, authenticationPath, outlet }: ProtectedRouteProps) => {
    const { sessionInfo, userInfo, authStatus }: IAuth = useContext(AuthContext)
    const location = useLocation()

    if (authStatus === AuthStatus.SignedIn && (userInfo?.pendingDocuments?.length ?? 0) > 0 && location.pathname !== '/terms') {
      return <Navigate to="/terms" />
    }

    if (authenticated !== undefined && !authenticated && authStatus === AuthStatus.SignedIn) {
      return <Navigate to="/integrations" />
    }

    if ((authenticated && authStatus === AuthStatus.SignedOut) || (group && !sessionInfo?.groups?.includes(group))) {
      return <Navigate to="/login" />
    }

    return outlet
  }

  return (
    <Routes>
      <Route path="/autologin/:challenge" element={<AutoLogin />} />

      <Route path="/" element={<ProtectedRoute authenticated={false} outlet={<NarrowLayout />} />}>
        <Route path="/auth/forgotpassword" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Route>

      <Route path="/" element={<ProtectedRoute authenticated={true} outlet={<NormalLayout />} />}>
        <Route path="/batches/:id" element={<ProtectedRoute group="resource-batch" outlet={<BatchView />} />} />
        <Route path="/batches" element={<ProtectedRoute group="resource-batch" outlet={<BatchList />} />} />
        <Route
          path="/batches/create-search"
          element={<ProtectedRoute group="resource-batch" outlet={<BatchSearchCreate />} />}
        />
        <Route
          path="/batches/create-profiling"
          element={<ProtectedRoute group="resource-batch" outlet={<BatchAnalysisCreate />} />}
        />
        <Route path="/analysis" element={<Analysis />} />
        <Route path="/analysis/:linkedinUrl" element={<Analysis />} />
        <Route path="/analysis/company-finder" element={<CompanyFinder />} />
        <Route path="/tasks/create" element={<ProtectedRoute group="resource-task" outlet={<TaskCreate />} />} />
        <Route path="/apikeys" element={<ProtectedRoute group="resource-apikey" outlet={<ApiKeys />} />} />

        <Route path="/admin" element={<ProtectedRoute group="group-admin" outlet={<Outlet />} />}>
          <Route path="/admin/roles" element={<AdminRolesList />} />
        </Route>
        
        <Route path="/superadmin" element={<ProtectedRoute group="role-admin" outlet={<Outlet />} />}>
          <Route path="/superadmin/integrations" element={<SuperAdminIntegrationList />} />
          <Route path="/superadmin/integrations/:id" element={<SuperAdminIntegrationView />} />
          <Route path="/superadmin/customers" element={<SuperAdminCustomerList />} />
          <Route path="/superadmin/customers/:id" element={<SuperAdminCustomerView />} />
        </Route>

        <Route path="/integrations" element={<IntegrationList />} />
        <Route path="/integrations-oauth-callback" element={<IntegrationCallback />} />
        <Route path="/integrations/create" element={<IntegrationCreate />} />
        <Route path="/integrations/:id" element={<Integration />}>
          <Route path="/integrations/:id/settings" element={<IntegrationSettings />} />
          <Route path="/integrations/:id/contacts" element={<IntegrationContacts />} />
        </Route>

        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/preferences" element={<Preferences />} />
      </Route>

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
}

const queryClient = new QueryClient()

const App: React.FunctionComponent = () => (
  <>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider />
        <CssBaseline />
        <Router>
          <AuthProvider>
            <MainRoute />
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  </>
)

export default App
