import { LinkedIn } from '@mui/icons-material';
import { Button, CircularProgress, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { formatDuration } from '../lib/date';

interface CompanyPositionsProps {
  company: any;
  onUse?: (company: any) => void;
  isEmailLoading?: boolean;
  data?: any;
}

export const ContactPosition: React.FunctionComponent<CompanyPositionsProps> = (
  props: CompanyPositionsProps
) => {
  return (
    <Box
      sx={{
        mb: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: 'flex', borderBottom: `1px solid rgba(0,0,0,0.12)` }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography sx={{ fontSize: '16px' }}>{props.company.company}</Typography>
          {props.onUse && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => props.onUse && props.onUse(props.company)}
              sx={{ padding: '0px 0px', mb: '5px', lineHeight: '12px', maxHeight: '20px' }}
              disabled={(props.data.email ?? '') === (props.company.email ?? '') && props.data?.title === props.company.positions[0].title && props.data?.company === props.company.company}
            >
              Use
            </Button>
          )}
        </Box>
        {props.company.companyLinkedinUrl && (
          <Link href={props.company.companyLinkedinUrl} target="_blank">
            <LinkedIn />
          </Link>
        )}
      </Box>

      {props.company.positions.map((position: any) => (
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>{position.title}</Typography>
          <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
            {position.start_at ? formatDuration(position.start_at, position.end_at) : 'Duration unknown'}
          </Typography>
          {position.end_at === null && 
            <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {props.isEmailLoading ? <><CircularProgress size="10px" sx={{mr: 1}} />Loading email...</> : (props.company.email ?? 'Email unknown')}
            </Typography>}
        </Box>
      ))}
    </Box>
  );
};
