import { Box, Divider, Breadcrumbs, Typography, Tabs, Tab } from '@mui/material';
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import { Outlet, useLocation, useParams, useResolvedPath, Link } from 'react-router-dom';
import { getApiV2 } from '../../libs/api';

export const Integration = () => {
  const { id } = useParams();
  const url = useResolvedPath("").pathname;
  const location = useLocation();

  const auth = React.useContext(AuthContext)

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [integration, setIntegration] = React.useState<any>({name: 'unknown', status:'', type:'', authorization_url: '', allow_authenticate: false, config: {}});

  interface LinkTabProps {
    label?: string;
    to?: string;
    value: string;
  }

  const LinkTab = (props: LinkTabProps) => {
    console.log(props.to);
    return (
      <Tab
        LinkComponent={Link}
        {...props}
      />
    );
  }

  const fetchData = async () => {
    setIsRefreshing(true);

    try {      
      const response = await (await getApiV2(auth)).get(`/integrations/${id}`)

      setIntegration(response.data)
    } catch (err) {
      console.log('Failed to get data ' + err)
    }

    setIsRefreshing(false);

    return [];
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  const getStatus = () => {
    switch (integration.status) {
      case 'CREATED': return 'not connected';
      case 'CONNECTED': return 'pending approval';
      case 'ACTIVE': return 'active';
      case 'PAUSED': return 'paused';
      case 'DELETED': return 'deleted';
    }
  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{m: 1}}>
          <Breadcrumbs>
            <Link to="#/integrations">Integrations</Link>
            <Typography color="text.primary">{integration.name}</Typography>
          </Breadcrumbs>
        </Box>
      </div>

      <Divider sx={{mb:0, mt:2}} />

      <Tabs sx={{mb: 2}} value={location.pathname} aria-label="nav tabs example">
        <LinkTab label="Settings" to={`${url}/settings`} value={`${url}/settings`} />
        {integration.config?.features?.includes('updater') && <LinkTab label="Contacts" to={`${url}/contacts`} value={`${url}/contacts`} />}
      </Tabs>

      <Outlet context={integration} />
      
    </>
  )
}
