import React from 'react'
import { MenuItem, Button, FormGroup, FormControlLabel, Checkbox, Grid, Typography, Alert } from '@mui/material'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import { Box } from '@mui/system'

interface CreateProfilingBatchFormProps {
  onSubmit: (values: { [key: string]: any }) => void
  onCancel: () => void
  columns: string[]
}

export const CreateProfilingBatchForm = (props: CreateProfilingBatchFormProps) => {
  const formRef = React.useRef<ValidatorForm>(null)
  const urlRef = React.useRef<SelectValidator>(null)
  const [values, setValues] = React.useState({
    name: '',
    mapping: {
      linkedinUrl: -1,
      firstName: -1,
      lastName: -1,
      companyName: -1,
    },
    features: [
      'age',
      'gender',
      'personality',
      'location',
      'experience',
      'education',
      'currentPosition'
    ],
    country: 'fi',
  })

  const valuesRef = React.useRef<typeof values>()
  valuesRef.current = values

  const features = {
    gender: 'Gender',
    personality: 'Personality (OCEAN and Early adopter)',
    age: 'Age',
    location: 'Location',
    experience: 'Experience (Current and previous positions)',
    education: 'Education (Degree, school and ISCED11)',
    currentPosition: 'Current position (Title, company and email)',
  }

  const countries = {
    fi: 'Finland',
    uk: 'Britain',
  }

  const [, setIsSubmitting] = React.useState(false)
  const [isFormValid, setIsFormValid] = React.useState(false)

  React.useEffect(() => {
    ValidatorForm.addValidationRule('urlOrName', (value) => {
      if (value === '-1') {
        if (
          valuesRef.current?.mapping.firstName === -1 ||
          valuesRef.current?.mapping.lastName === -1 ||
          valuesRef.current?.mapping.companyName === -1
        ) {
          return false
        }

        return true
      }

      return true
    })
  }, [])

  React.useEffect(() => {
    const validate = async () => {
      if (formRef !== null && formRef.current !== null) {
        setIsFormValid(await formRef.current.isFormValid(true))
      }
    }

    validate()

    // force linkedin url validation
    urlRef.current?.validate(values.mapping.linkedinUrl.toString())
  }, [values])

  const getOptions = () => {
    var options = props.columns.map((e: any, index: number): any => ({
      label: e,
      value: index,
    }))

    options.unshift({
      label: 'None',
      value: -1,
    })

    return options
  }

  const handleChange = (event: any) => {
    handleChangeParam(event, null)
  }

  const handleChangeParam = (event: any, paramName: string | null = null) => {
    const { name, value } = event.target

    if (paramName === null) {
      setValues({ ...values, [name]: value })
    } else {
      setValues({
        ...values,
        [paramName]: {
          ...(values as any)[paramName],
          [name]: value,
        },
      })

      console.log({
        ...values,
        [paramName]: {
          ...(values as any)[paramName],
          [name]: value,
        },
      })
    }
  }

  const handleCheckboxChange = (event: any, paramName: string) => {
    const { name } = event.target

    const has: boolean = (values as any)[paramName].some((item: string) => item === name)

    var newItems = (values as any)[paramName].filter((item: string) => item !== name)
    if (!has) {
      newItems.push(name)
    }

    setValues({
      ...values,
      [paramName]: newItems,
    })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await props.onSubmit(values)
    setIsSubmitting(false)
  }

  return (
    <ValidatorForm
      ref={formRef}
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      sx={{
        borderWidth: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}>
      <Typography variant="h3">Title</Typography>
      <Grid container gap={1} sx={{ width: '50%' }}>
        <Grid item sm={12} mb={3}>
          <TextValidator
            label="Name for batch job"
            onChange={handleChange}
            name="name"
            validators={['required', 'maxStringLength:48']}
            errorMessages={['Kenttä on pakollinen', 'Maksimipituus 48 merkkiä']}
            value={values.name}
            fullWidth
          />
        </Grid>

        <Typography variant="h3">Column mappings</Typography>
        <Alert severity="info">
          You must define either linkedin URL mapping OR first name, lastname and company mapping (all three). You can
          also define everything, in which case linkedin URL is preferred, but name+company search is utilised when URL
          is missing or malformed.
        </Alert>

        <Grid item sm={12} mb={3}>
          <Box mb={1}>
            <SelectValidator
              ref={urlRef}
              label="Linkedin URL"
              name="linkedinUrl"
              id="linkedinUrl"
              validators={['urlOrName']}
              errorMessages={['Linkedin URL OR name and company fields are required']}
              onChange={(event) => handleChangeParam(event, 'mapping')}
              value={values.mapping.linkedinUrl}
              fullWidth>
              {getOptions().map((item, index) => (
                <MenuItem key={`linkedinUrl_${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Box>

          <Box mb={1}>
            <SelectValidator
              label="First name"
              name="firstName"
              id="firstName"
              onChange={(event) => handleChangeParam(event, 'mapping')}
              value={values.mapping.firstName}
              fullWidth>
              {getOptions().map((item, index) => (
                <MenuItem key={`firstName_${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Box>

          <Box mb={1}>
            <SelectValidator
              label="Last name"
              name="lastName"
              id="lastName"
              onChange={(event) => handleChangeParam(event, 'mapping')}
              value={values.mapping.lastName}
              fullWidth>
              {getOptions().map((item, index) => (
                <MenuItem key={`lastName_${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Box>

          <Box mb={1}>
            <SelectValidator
              label="Company name"
              name="companyName"
              id="companyName"
              onChange={(event) => handleChangeParam(event, 'mapping')}
              value={values.mapping.companyName}
              fullWidth>
              {getOptions().map((item, index) => (
                <MenuItem key={`companyName_${index}`} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </SelectValidator>
          </Box>
        </Grid>

        <Typography variant="h3">Features</Typography>
        <Grid item sm={12}>
          <FormGroup>
            {Object.entries(features).map(([key, value], index) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleCheckboxChange(event, 'features')
                    }}
                    checked={(values as any).features.includes(key)}
                    name={key}
                  />
                }
                label={value}
              />
            ))}
          </FormGroup>
        </Grid>

        <Typography variant="h3">Additional settings</Typography>
        <Grid item sm={12}>
          <Box mb={1}>
            <SelectValidator
              label="Country of preference"
              name="country"
              id="country"
              onChange={handleChange}
              value={values.country}
              fullWidth>
              {Object.keys(countries).map((key) => (
                <MenuItem key={`country_${key}`} value={key}>
                  {countries[key as keyof typeof countries]}
                </MenuItem>
              ))}
            </SelectValidator>
          </Box>
        </Grid>

        <Grid item sm={6}>
          <Button sx={{ mr: 1 }} variant="outlined" onClick={props.onCancel}>
            Back
          </Button>
          <Button type="submit" disabled={!isFormValid} variant="contained">
            Next
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}
