import { Box, Skeleton, Typography } from '@mui/material';

interface ContactPositionSkeletonProps {
  showOnUse: boolean;
}

export const ContactPositionSkeleton = (props: ContactPositionSkeletonProps) => (
  <Box
    sx={{
      mb: 2,
      mt: 1
    }}
  >
    <Box sx={{ display: 'flex', borderBottom: `1px solid rgba(0,0,0,0.12)` }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ fontSize: '16px' }}>
          <Skeleton width={80} />
        </Typography>

        {props.showOnUse && <Skeleton width={60} height={30} />}
      </Box>
    </Box>

    <Box sx={{ mb: 1 }}>
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
        <Skeleton width={120} />
      </Typography>
      <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
        <Skeleton width={150} />
      </Typography>
    </Box>
  </Box>
);
