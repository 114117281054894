import { useParams } from 'react-router-dom'
import MaterialReactTable, { MRT_ColumnFiltersState, MRT_SortingState } from 'material-react-table'
import { Box, Breadcrumbs, Link, Typography, Button, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Download, Refresh, Replay } from '@mui/icons-material'
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import { Cancel } from '@mui/icons-material'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { getApiV2 } from '../../libs/api'

export const BatchViewRaw = () => {
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<MRT_SortingState>([])
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 50,
  })

  const { id } = useParams()

  const auth = React.useContext(AuthContext)

  React.useEffect(() => {
    setRowSelection([])
  }, [pagination])

  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      columnFilters, //refetch when columnFilters changes
      globalFilter, //refetch when globalFilter changes
      pagination.pageIndex, //refetch when pagination.pageIndex changes
      pagination.pageSize, //refetch when pagination.pageSize changes
      sorting, //refetch when sorting changes
    ],

    queryFn: async () => {
      const response = await (
        await getApiV2(auth)
      ).get(`/batches/${id}`, {
        params: {
          start: pagination.pageIndex * pagination.pageSize,
          size: pagination.pageSize,
          filters: JSON.stringify(columnFilters ?? []),
          globalFilter: globalFilter ?? '',
          sorting: JSON.stringify(sorting ?? []),
        },
      })

      const columns = [
        {
          accessorKey: 'status',
          header: 'Status',
        },
        ...response.data.columns,
      ]

      const data = response.data.data.map((row: any) => {
        var item: any = { id: row.id, status: row.status }

        for (const index in columns) {
          if (index === '0') {
            continue
          }
          item[columns[index].accessorKey] = row.data[parseInt(index) - 1]
        }

        //console.log(item)
        return item
      })

      return {
        meta: response.data.meta,
        columns,
        data,
      }
    },
    keepPreviousData: true,
    retry: 0,
  })

  const retrySelected = async () => {
    await (
      await getApiV2(auth)
    ).put(`/batches/${id}`, {
      batchJobId: id,
      units: Object.keys(rowSelection).map((index) => data?.data[index].id.split('_')[0]),
    })

    await refetch()

    setRowSelection([])
  }

  const downloadFile = async (id: string, type: string) => {
    const response = await (
      await getApiV2(auth)
    ).get(`/batches/${id}/files`, {
      params: {
        type: type,
      },
    })

    downloadUrl(response.data.url)
  }

  const downloadUrl = (url: string) => {
    console.log(url)
    const link = document.createElement('a')
    link.download = 'true'
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const abort = async (id: string) => {
    await (await getApiV2(auth)).patch(`/batches/${id}`)
    await refetch()
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Box sx={{ m: 1 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="#/batches">
              Batches
            </Link>
            <Typography color="text.primary">Batch {id}</Typography>
          </Breadcrumbs>
        </Box>

        <div>
          <LoadingButton
            sx={{ mr: 1 }}
            variant="outlined"
            startIcon={<Refresh />}
            onClick={() => refetch()}
            loadingPosition="start"
            loading={isLoading}>
            Refresh
          </LoadingButton>
          <Button
            sx={{ m: 1 }}
            startIcon={<Replay />}
            variant="outlined"
            onClick={() => retrySelected()}
            disabled={Object.keys(rowSelection).length === 0 || data?.meta.type === 'SEARCH'}>
            Retry selected
          </Button>
          {['CREATED', 'IN_PROGRESS'].includes(data?.meta.status) && (
            <Button sx={{ m: 1 }} startIcon={<Cancel />} variant="outlined" onClick={() => abort(id!)}>
              Abort processing
            </Button>
          )}
          <Button
            sx={{ m: 1 }}
            startIcon={<Download />}
            variant="outlined"
            onClick={() => downloadFile(id!, 'in')}
            disabled={!data?.meta.has_input_file}>
            Download input file
          </Button>
          <Button
            sx={{ m: 1 }}
            startIcon={<Download />}
            variant="outlined"
            onClick={() => downloadFile(id!, 'out')}
            disabled={!data?.meta.has_output_file}>
            Download output file
          </Button>
        </div>
      </Box>

      {data?.meta.version >= 2 && (
        <Box sx={{ height: '80vh', width: '100%' }}>
          <MaterialReactTable
            data={data?.data}
            columns={data?.columns ?? []}
            enableRowSelection={(row: any) => !['CREATED', 'IN_PROGRESS'].includes(row.original.status)}
            enableMultiRowSelection={true}
            enableColumnResizing={true}
            manualFiltering
            manualPagination
            manualSorting
            enablePinning={true}
            rowCount={data?.meta?.total_rows ?? 0}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            onRowSelectionChange={setRowSelection}
            muiTablePaginationProps={{
              showFirstButton: true,
              showLastButton: true,
            }}
            state={{
              columnFilters,
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isFetching,
              sorting,
              rowSelection,
            }}
          />
        </Box>
      )}

      {data?.meta.version < 2 && (
        <Alert severity="warning">
          The data is saved in older version. You will still be able to download input and output files, but quick view
          has been deprecated for this batch.
        </Alert>
      )}
    </QueryClientProvider>
  )
}

const queryClient = new QueryClient()

export const BatchView = () => (
  <QueryClientProvider client={queryClient}>
    <BatchViewRaw />
  </QueryClientProvider>
)
