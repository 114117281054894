import React from 'react';
import { Button, Grid } from '@mui/material';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { LoadingButton } from '@mui/lab';

interface ForgotPasswordUpdateFormProps {
    onSubmit: (code: string, password:string) => void;
}

export const ForgotPasswordUpdateForm = (props:ForgotPasswordUpdateFormProps) => {
    const [values, setValues] = React.useState({
        code: '',
        password: '',
        repeatPassword: ''
    });

    const valuesRef = React.useRef<typeof values>();
    valuesRef.current = values;

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const formRef = React.useRef<ValidatorForm>(null);
    const [isFormValid, setIsFormValid] = React.useState(false);

    React.useEffect(() => {
        const validate = async () => {
            if (formRef !== null && formRef.current !== null) {
                setIsFormValid(await formRef.current.isFormValid(true));
            }
        }

        validate();
    }, [values])

    React.useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            console.log(`${value} - ${valuesRef.current?.password}`)
            if (value !== valuesRef.current?.password) {
                return false;
            }
            return true;
        });
    }, [])

    const handleChange = (event:any) => {
        const { name, value, type } = event.target;

        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        await props.onSubmit(values.code, values.password);
        setIsSubmitting(false);
    }

    return (
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
                sx={{borderWidth:'5px',display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start'}}
            >
                <Grid container gap={1}>
                    <Grid item xs={12}>
                        <TextValidator
                            label="Code"
                            onChange={handleChange}
                            name="code"
                            validators={["required"]}
                            errorMessages={["Please input verification code"]}
                            value={values.code}
                            fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextValidator
                            label="Password"
                            type="password"
                            onChange={handleChange}
                            name="password"
                            validators={["required", "minStringLength:8"]}
                            errorMessages={["Password is required", "Password must be at least 8 characters"]}
                            value={values.password}
                            fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <TextValidator
                            label="Repeat password"
                            type="password"
                            onChange={handleChange}
                            name="repeatPassword"
                            validators={["required", "isPasswordMatch"]}
                            errorMessages={["Plase enter password again", "Passwords do not match"]}
                            value={values.repeatPassword}
                            fullWidth />
                    </Grid>

                    <Grid item sm={6}>
                        <LoadingButton loading={isSubmitting} type="submit" disabled={!isFormValid} variant="contained">Restore password</LoadingButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
}