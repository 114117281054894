import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { SxProps } from '@mui/material';

interface ConfirmDialogProps {
    title: string;
    open: boolean;
    children?: JSX.Element[];
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
    sx: SxProps;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { title, children, open, setOpen, onConfirm, sx } = props;
  return (
      <Dialog
        sx={sx}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
};