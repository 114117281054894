import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Grid } from '@mui/material';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { LoadingButton } from '@mui/lab';

interface TaskProfileFormProps {
    linkedinUrl: string;
    onSubmit: (linkedinUrl:string, features: string[]) => void;
}

export const TaskProfileForm = (props:TaskProfileFormProps) => {
    const [features, setFeatures] = React.useState({
        email: true,
        gender: true,
        ocean: false,
        age: true,
        location: true,
        experience: true,
        education: true,
        currentPosition: true,
        personality: true
    })

    const [linkedinUrl, setLinkedinUrl] = React.useState('')

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const formRef = React.useRef<ValidatorForm>(null);
    const [isFormValid, setIsFormValid] = React.useState(false);

    React.useEffect(() => {
        setLinkedinUrl(props.linkedinUrl ?? '');
    }, [props.linkedinUrl])

    React.useEffect(() => {
        const validate = async () => {
            if (formRef !== null && formRef.current !== null) {
                setIsFormValid(await formRef.current.isFormValid(true));
            }
        }

        validate();
    }, [linkedinUrl, features])

    React.useEffect(() => {
        console.log(features);
    }, [features]);

    const handleFieldChange = (event:any) => {
        const { name, value, type } = event.target;
        setLinkedinUrl(value)
    }

    const handleFeaturesChange = (event:any) => {
        const { name } = event.target;

        setFeatures({
            ...features,
            [name]: !(features[name as keyof typeof features] as boolean)
        });
    }

    const handleSubmit = async () => {
        var list: string[] = [];

        for (const key in features) {
            if (features[key as keyof typeof features]) {
                list.push(key);
            }
        }

        setIsSubmitting(true);
        await props.onSubmit(linkedinUrl, list);
        setIsSubmitting(false);
    }

    return (
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
                sx={{borderWidth:'5px',display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'flex-start'}}
            >
                <Grid container gap={1}>

                    <Grid item sm={12}>
                        <TextValidator
                                label="Linkedin URL"
                                onChange={handleFieldChange}
                                name="linkedinUrl"
                                validators={["required"]}
                                errorMessages={["Kenttä on pakollinen"]}
                                value={linkedinUrl}
                                fullWidth />
                    </Grid>

                    <Grid item sm={12}>
                        <FormGroup>
                            {[
                                {
                                    name: 'gender',
                                    label: 'Sukupuoli'
                                },
                                {
                                    name: 'age',
                                    label: 'Syntymävuosi, Ikä'
                                },
                                {
                                    name: 'email',
                                    label: 'Sähköpostiosoite'
                                },
                                {
                                    name: 'location',
                                    label: 'Sijainti (maa, kaupunki)'
                                },
                                {
                                    name: 'experience',
                                    label: 'Työkokemus'
                                },
                                {
                                    name: 'education',
                                    label: 'Koulutus'
                                },
                                {
                                    name: 'currentPosition',
                                    label: 'Nykyinen työpaikka'
                                },
                                {
                                    name: 'personality',
                                    label: 'Personallisuus (OCEAN)'
                                },
                            ].map((item) => (
                                <FormControlLabel
                                    key={item.name}
                                    control={
                                        <Checkbox
                                            onChange={handleFeaturesChange}
                                            checked={features[item.name as keyof typeof features]}
                                            name={item.name} />}
                                            label={item.label} />
                            ))}
                        </FormGroup>
                    </Grid>
                    <Grid item sm={6}>
                        <LoadingButton loading={isSubmitting} type="submit" disabled={!isFormValid} variant="contained">Search</LoadingButton>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
}