import { Button, Box, Divider, Breadcrumbs, Link, Typography, Stepper, Step, StepLabel } from '@mui/material'
import { parse } from 'papaparse'
import React from 'react'
import axios from 'axios'
import { AuthContext } from '../../contexts/authContext'
import { CreateSearchBatchForm } from '../../forms/createSearchBatchForm'
import { DataTable } from '../../components/DataTable'
import { LoadingButton } from '@mui/lab'
import { getApiV2 } from '../../libs/api'
import { MuiFileInput } from 'mui-file-input'

interface PreviewType {
  headers: any[]
  data: any[]
}

export const BatchSearchCreate = () => {
  const [file, setFile] = React.useState<any>(null)
  const [formData, setFormData] = React.useState({})

  const [currentStep, setCurrentStep] = React.useState(0)
  const [preview, setPreview] = React.useState({ headers: [], data: [] } as PreviewType)
  const [uploadStarted, setUploadStarted] = React.useState(false)
  const auth = React.useContext(AuthContext)

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const parsePreview = (_headers: string[], _data: object[]) => {
    const headers = _headers.map((item: string) => ({
      field: item,
      label: item,
    }))

    const data = _data.map((item, index) => ({
      id: index,
      ...item,
    }))

    setPreview({ headers: headers, data: data })
  }

  const onFormSubmit = (params: { [key: string]: string }) => {
    setFormData(params)
    next()
  }

  const upload = async () => {
    setUploadStarted(true)

    const data = new FormData()
    data.append('files[]', file as any)

    const response = await (await getApiV2(auth)).post('batches', { ...formData, type: 'search' })

    const url = response.data.url

    await axios.put(url, file)

    next()
  }

  const handleFileChange = (newFile: any) => {
    setFile(newFile)

    parse(newFile, {
      worker: true,
      header: true,
      preview: 5,
      skipEmptyLines: true,
      complete: (result: any, file: any) => {
        console.log(result)
        parsePreview(result.meta.fields as string[], result.data as object[])
      },
    })
  }

  const steps = [
    {
      title: 'Select file',
      content: (
        <>
          <MuiFileInput placeholder="Choose a file..." value={file} onChange={handleFileChange} />

          {preview.headers.length > 0 && (
            <>
              <p>
                This is a preview of the first 5 lines (including possible header) in the file. Please confirm the file
                looks correct and edit the file and retry if not.
              </p>
              <DataTable isLoading={false} items={preview.data} headers={preview.headers} />
              <Button sx={{ mt: 2 }} onClick={() => next()} variant="contained">
                Next
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Map columns',
      content: (
        <CreateSearchBatchForm
          onCancel={() => prev()}
          onSubmit={onFormSubmit}
          columns={preview.headers.map((i: any) => i.field)}
        />
      ),
    },
    {
      title: 'Upload file',
      content: (
        <>
          <p>
            After uploading the file, it will be processed. This will take a little while. You will be charged for each
            successful profiling.
          </p>
          <Button sx={{ mr: 1 }} variant="outlined" onClick={prev}>
            Back
          </Button>
          <LoadingButton variant="contained" onClick={upload} loading={uploadStarted}>
            Upload now
          </LoadingButton>
        </>
      ),
    },
    {
      title: 'Completed',
      content: (
        <>
          <h1>Processing has been initiated.</h1>
          <p>
            <Link href="#/batches">
              <Button variant="contained">View status</Button>
            </Link>
          </p>
        </>
      ),
    },
  ]

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/batches">
            Batches
          </Link>
          <Typography color="text.primary">Create new search batch</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Stepper activeStep={currentStep} sx={{ mb: 2 }}>
        {steps.map((step) => (
          <Step key={step.title} completed={false}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="steps-content">{steps[currentStep].content}</div>
    </>
  )
}
