import { Chip, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { getApiV2 } from '../../libs/api';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { AuthContext } from '../../contexts/authContext';
import { LoadingButton } from '@mui/lab';

export const IntegrationSettings = () => {
  const { id } = useParams();

  const auth = React.useContext(AuthContext)
  const integration: any = useOutletContext();

  const { mutate: createAuthorizationUrl, isLoading } = useMutation(
    async () => {
      return await (await getApiV2(auth)).post('/integration-authorizations',{
        type: 'HUBSPOT',
        integrationId: id
      })
    },
    {
      onSuccess: (response) => {
        if (response.status === 201) {
          localStorage.setItem('callbackIntegrationId', id!);
          window.location = response.data.authorization_url;
        } else {
          enqueueSnackbar('Something went wrong!', { variant: 'error' })
        }
      }
    }
  )

  const getStatus = () => {
    switch (integration.status) {
      case 'CREATED': return 'not connected';
      case 'CONNECTED': return 'pending approval';
      case 'ACTIVE': return 'active';
      case 'PAUSED': return 'paused';
      case 'DELETED': return 'deleted';
    }
  }

  return (
    <>
      <Typography color="text.secondary">Name</Typography>
      <Typography gutterBottom>{integration.name}</Typography>

      <Typography color="text.secondary">Type</Typography>
      <Chip sx={{mb: 1}} color="info" size="small" label={integration.type} />

      <Typography color="text.secondary">Status</Typography>
      <Chip sx={{mb: 1}} color="info" size="small" label={getStatus()} />

      {integration.allow_authenticate &&
        <>
          {integration.status === 'CREATED' && <p>Enable integration by authorizing Traitspotter for Hubspot access.</p>}
          {integration.status === 'ACTIVE' && <p>Your integration is missing one or more privileges. Please reauthenticate to enable all the features for your HubSpot integration.</p>}
          {integration.status === 'PAUSED' && <p>Your integration has been temporarily disabled.</p>}

          <LoadingButton loading={isLoading} variant="contained" onClick={() => createAuthorizationUrl()}>Authenticate</LoadingButton>
        </>
      }
      
    </>
  )
}
