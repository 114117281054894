import { Alert, AlertTitle, Breadcrumbs, Button, CircularProgress, Divider, Grid, Link, Paper, Skeleton, Typography, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { Contact } from '../../components/Contact'
import { Box } from '@mui/system'
import { AuthContext } from '../../contexts/authContext'
import { getApiV2 } from '../../libs/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ContactInputs } from '../../components/ContactInputs'
import { LinkedIn } from '@mui/icons-material'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { useParams } from 'react-router-dom'

const SuccessBox = styled(Box)(({ theme }) => ({
  margin: 'auto',
  lineHeight: '16px',
  padding: '0 8px',
  color: 'white',
  border: `1px solid ${theme.palette.success.dark}`,
  backgroundColor: theme.palette.success.main,
  fontSize: '12px',
  fontWeight: 800,
  textTransform: 'uppercase',
  borderRadius: '5px',
  boxShadow: `inset 0 0 8px ${theme.palette.success.dark}`,
}))

const WarningBox = styled(SuccessBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.warning.dark}`,
  backgroundColor: theme.palette.warning.main,
  boxShadow: `inset 0 0 8px ${theme.palette.warning.dark}`,
}))

const ErrorBox = styled(SuccessBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.dark}`,
  backgroundColor: theme.palette.error.main,
  boxShadow: `inset 0 0 8px ${theme.palette.error.dark}`,
}))

const InfoBox = styled(SuccessBox)(({ theme }) => ({
  border: `1px solid ${theme.palette.info.dark}`,
  backgroundColor: theme.palette.info.main,
  boxShadow: `inset 0 0 8px ${theme.palette.info.dark}`,
}))

export const Analysis = () => {
  const queryClient = useQueryClient()
  const auth = React.useContext(AuthContext)
  const { linkedinUrl } = useParams()

  const emails: any[] = []
  const [relatedContacts, setRelatedContacts] = React.useState<any[]>([])
  const [previous, setPrevious] = React.useState<any[]>([])
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const inputRef = React.useRef<any>(null)

  const queryDefaults = {
    refetchOnMount: false,
    refetchInterval: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  }

  const fetchPrevious = async () => {
    const prev = await (await getApiV2(auth)).get('/analysis')

    setPrevious(prev.data.results)
  }

  React.useEffect(() => {
    if (linkedinUrl) {
      inputRef.current.searchWith({ linkedinUrl })
    }
  }, [])

  const { isFetching: isLoading, data } = useQuery(
    ['contact-analysis', currentId],
    async () => {
      console.log(`Loading analysis with id ${currentId}`)

      const response = await (await getApiV2(auth)).get(`/analysis/${currentId}`)

      console.log('Loading analysis done')

      return response.data
    },
    {
      ...queryDefaults,
      enabled: currentId !== null,
      refetchInterval: (data: any) => (data && data?.status === 'PENDING' ? 5000 : false),
    }
  )

  const postAnalysis = async (params: any) => {
    console.log(`Fetch with ${JSON.stringify(params)}`)
    queryClient.setQueryData(['contact-analysis'], {})

    const name = params.name?.split(' ')
    try {
      const response = await (
        await getApiV2(auth)
      ).post('/analysis', {
        ...params,
        firstName: name?.[0],
        lastName: name?.[1],
      })

      setCurrentId(response.data.id)
      setPrevious([{ ...response.data.search, id: response.data.id }, ...previous])
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 403) {
        enqueueSnackbar('Insufficient credits', { variant: 'error' })
      }
    }
  }

  React.useEffect(() => {
    fetchPrevious()
  }, [])

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs>
          <Typography color="text.primary">Analysis builder</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{mb:2, mt:2}} />

      <Alert severity="info">
        <AlertTitle>Instructions</AlertTitle>
        <p>
          Get personality analysis and insights for successful sales communication with contact.
          See also current and past positions & Demographic data.
        </p>
        <p>
          Get started by providing the name of your contact and either company name or title.
          It doesn't need to be exact nor for current position, just some clue to us to identify the person since name rarely is unique.
        </p>
      </Alert>

      <ContactInputs
        myRef={inputRef}
        analysis={data?.analysis ?? {}}
        onSearch={postAnalysis}
        previous={previous}
        onSelectPrevious={(id) => {
          setCurrentId(id)
        }}
      />

      {data && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', flexWrap: 'wrap', mt: 2 }}>
          <Box sx={{ marginRight: 'auto', mb: 3 }}>
            {data?.status === 'FOUND' && <SuccessBox>success</SuccessBox>}
            {data?.status === 'FAILED' && <ErrorBox>analysis failed</ErrorBox>}
            {data?.status === 'NOT_FOUND' && <WarningBox>not found</WarningBox>}
            {(isLoading || data?.status === 'PENDING') && (
              <InfoBox>
                <CircularProgress sx={{ color: 'white', mr: 1 }} size={9} />
                pending
              </InfoBox>
            )}
          </Box>
          {data?.analysis || ['FOUND'].includes(data?.status) ? (
            <Contact
              analysis={{
                ...data.analysis,
                experience: {
                  ...data.analysis.experience,
                  positions: data.analysis.experience.positions.map((position: any) => ({
                    ...position,
                    email: emails?.find((email: any) => email.company === position.company)?.email,
                  })),
                },
              }}
              data={true}
              onCancelImport={() => {}}
              relatedContacts={relatedContacts}
              isLoading={!data?.analysis || data.analysis?.status === 'PENDING'}
              isEmailsLoading={false}
            />
          ) : (
            <Box>
              {data?.suggestions?.length > 0 && (
                <>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Could one of these be your contact?
                  </Typography>
                  <Typography variant="body2">
                    Please note that the information below may not always represent the most up-to-date positions for
                    contacts. Don't worry though - once we match your contact, we will make sure you have the latest
                    position data.
                  </Typography>
                  {data.suggestions?.map((suggestion: any) => (
                    <Box
                      sx={{
                        mb: 2,
                        mt: 3,
                        maxWidth: '400px',
                      }}>
                      <Box sx={{ display: 'flex', borderBottom: `1px solid rgba(0,0,0,0.12)` }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ fontSize: '16px' }}>
                              {suggestion.firstName} {suggestion.lastName}
                            </Typography>
                            {suggestion.linkedinUrl && (
                              <Link href={suggestion.linkedinUrl} target="_blank">
                                <LinkedIn />
                              </Link>
                            )}
                          </Box>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={async () =>
                              await inputRef.current.searchWith({
                                name: suggestion.firstName + ' ' + suggestion.lastName,
                                company: suggestion.currentPosition?.company,
                                title: suggestion.currentPosition?.title,
                              })
                            }
                            sx={{ pl: 1, pr: 1, mb: '5px', lineHeight: '12px', maxHeight: '20px' }}>
                            Search
                          </Button>
                        </Box>
                      </Box>

                      <Box sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
                          {suggestion.currentPosition?.title}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
                          {suggestion.currentPosition?.company}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
