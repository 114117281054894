import { Box, Divider, Breadcrumbs, Link, Typography, Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import { useNavigate } from 'react-router-dom'
import { getApiV2 } from '../../libs/api'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'

export const IntegrationCallback = () => {
  const nav = useNavigate()
  const auth = React.useContext(AuthContext)

  const { mutate: createIntegration } = useMutation(
    async (code: string) => {
      const id = localStorage.getItem('callbackIntegrationId')

      if (id) {
        return await (
          await getApiV2(auth)
        ).patch(`/integrations/${id}`, {
          action: 'CONNECT',
          type: 'HUBSPOT',
          code: code,
        })
      } else {
        return await (
          await getApiV2(auth)
        ).post(`/integrations`, {
          type: 'HUBSPOT',
          code: code,
        })
      }
    },
    {
      onSuccess: (response) => {
        if ([200, 201].includes(response.status)) {
          console.log(response)
          nav(`/integrations/${response.data.integrationId}/settings`)
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to create integration', { variant: 'error' })
      },
    }
  )

  React.useEffect(() => {
    const code = /code=([a-z0-9\-]+)/.exec(window.location.href)

    if (code == null) {
      throw new Error('Missing code or integration id')
    }

    createIntegration(code[1])
  }, [])

  return (
    <>
      <Box sx={{ m: 1 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="#/integrations">
            Integrations
          </Link>
          <Typography color="text.primary">Integration OAuth callback</Typography>
        </Breadcrumbs>
      </Box>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
