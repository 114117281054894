import { AppBar, Avatar, Box, Button, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'

interface TSAppBarProps {
  onSignOut: () => void
  username: string
}

export const TSAppBar: React.FunctionComponent<TSAppBarProps> = (props: TSAppBarProps) => {
  const auth = React.useContext(AuthContext);

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ zIndex: 10000, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link to="/">
          <Box sx={{ mt: 1 }}>
            <img src="/logo.png" alt="Traitspotter logo" height="40px" />
          </Box>
        </Link>
        <Box sx={{ m: 1, mr: 0, p: 0, display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 48, height: 48, mr: 1 }} />
          <Box m={1} sx={{flexDirection: 'column', display: 'wrap', flexWrap: 'wrap'}}>
            <Typography variant="subtitle1">{props.username}</Typography>
            <Typography variant="caption" sx={{ color: 'white' }}>
              Credits: {auth.userInfo?.credits}
            </Typography>
          </Box>
          <Button variant="outlined" sx={{ borderColor: 'white', ml: 1, color: 'white' }} onClick={props.onSignOut}>
            Sign out
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
