import React, { useState, useContext } from 'react'

import { useNavigate } from 'react-router-dom'
import { Alert, Button, Typography } from '@mui/material'

import { AuthContext } from '../../contexts/authContext'
import { ForgotPasswordForm } from '../../forms/forgotPasswordForm'
import { ForgotPasswordUpdateForm } from '../../forms/forgotPasswordUpdateForm'

enum Phase {
  VERIFY = 1,
  CHANGE = 2,
  COMPLETED = 3,
}

export const ForgotPassword: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = React.useState('')
  const [error, setError] = useState('')
  const [phase, setPhase] = React.useState(Phase.VERIFY)

  const nav = useNavigate()

  const authContext = useContext(AuthContext)

  const onInit = async (email: string) => {
    setEmail(email)

    try {
      await authContext.sendCode(email)
      setPhase(Phase.CHANGE)
    } catch (err) {
      setError('Unknown user')
    }
  }

  const onSetPassword = async (code: string, password: string) => {
    try {
      await authContext.forgotPassword(email, code, password)
      setPhase(Phase.COMPLETED)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
  }

  const completedView = (
    <>
      <h1>Password updated</h1>
      <Button onClick={() => nav('/')} variant="contained">
        Sign in
      </Button>
    </>
  )

  return (
    <>
      <Typography sx={{ mb: 3, marginRight: 'auto' }} component="h1" variant="h5">
        Forgot password{' '}
      </Typography>

      {phase === Phase.VERIFY && <ForgotPasswordForm onSubmit={onInit} />}
      {phase === Phase.CHANGE && <ForgotPasswordUpdateForm onSubmit={onSetPassword} />}
      {phase === Phase.COMPLETED && completedView}

      {error && (
        <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
          {error}{' '}
        </Alert>
      )}
    </>
  )
}
