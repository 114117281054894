import React from 'react'
import {
  MenuItem,
  Button,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
} from '@mui/material'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import { customArray } from 'country-codes-list'

interface CreateSearchBatchFormProps {
  onSubmit: (values: { [key: string]: any }) => void
  onCancel: () => void
  columns: string[]
}

export const CreateSearchBatchForm = (props: CreateSearchBatchFormProps) => {
  const functions = {
    academic: 'Academic',
    administrative: 'Administrative',
    business: 'Business',
    consultant: 'Consultants',
    customerService: 'Customer service',
    creative: 'Creative',
    ecommerce: 'E-Commerce',
    elementary: 'Elementary',
    engineer: 'Engineers',
    finance: 'Finance',
    healthCare: 'Health care',
    hr: 'Human resources',
    legal: 'Legal and compliance',
    marketing: 'Marketing',
    operations: 'Operations',
    product: 'Product',
    production: 'Production',
    project: 'Project',
    purchasing: 'Purchasing',
    realEstate: 'Real estate',
    sales: 'Sales',
    sustainability: 'Sustainability and environment',
    technology: 'Technology',
    quality: 'Quality',
  }

  const levels = {
    entry: 'Entry level',
    contributor: 'Contributors',
    manager: 'Manager',
    director: 'Director',
    vp: 'Vice president',
    csuite: 'C-Suite',
    board: 'Board of directors',
    owner: 'Owners',
  }

  const greed = {
    low: 'Basic (faster, lower quantity)',
    medium: 'Intermediate',
    high: 'Advanced (slower, highest quantity)',
  }

  const strict = {
    low: 'Very low (higher quantity, lower quality)',
    medium: 'Average',
    high: 'Very high (lower quantity, higher quality)',
  }

  const countries: any = Object.fromEntries(
    customArray({ countryCode: '{countryCode}', countryName: '{countryNameEn}' }, { sortBy: 'countryName' }).map(
      (item) => [item.countryCode.toLowerCase(), item.countryName]
    )
  )

  const formRef = React.useRef<ValidatorForm>(null)
  const [values, setValues] = React.useState({
    name: '',
    mapping: {
      companyName: '-1',
    },
    filterFunctions: 'none',
    functions: Object.keys(functions),
    customFunctions: '',
    filterLevels: 'none',
    levels: Object.keys(levels),
    country: 'fi',
    strictCountry: '0',
    greed: 'low',
    strict: 'medium',
  })

  const [, setIsSubmitting] = React.useState(false)
  const [isFormValid, setIsFormValid] = React.useState(false)

  React.useEffect(() => {
    const validate = async () => {
      if (formRef !== null && formRef.current !== null) {
        setIsFormValid(await formRef.current.isFormValid(true))
      }
    }

    validate()
  }, [values])

  const getOptions = () => {
    var options = props.columns.map((e: any, index: number): any => ({
      label: e,
      value: index,
    }))

    options.unshift({
      label: 'None',
      value: '-1',
    })

    return options
  }

  const handleChange = (event: any) => {
    handleChangeParam(event, null)
  }

  const handleChangeParam = (event: any, paramName: string | null = null) => {
    const { name, value, type } = event.target

    if (type == 'checkbox') {
      setValues({ ...values, [name]: !(values as any)[name] })
    } else if (paramName === null) {
      setValues({ ...values, [name]: value })
    } else {
      setValues({
        ...values,
        [paramName]: {
          ...(values as any)[paramName],
          [name]: value,
        },
      })

      console.log({
        ...values,
        [paramName]: {
          ...(values as any)[paramName],
          [name]: value,
        },
      })
    }
  }

  const handleCheckboxChange = (event: any, paramName: string) => {
    const { name } = event.target

    const has: boolean = (values as any)[paramName].some((item: string) => item === name)

    var newItems = (values as any)[paramName].filter((item: string) => item !== name)
    if (!has) {
      newItems.push(name)
    }

    setValues({
      ...values,
      [paramName]: newItems,
    })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    await props.onSubmit({
      ...values,
      strictCountry: values.strictCountry === '1',
    })
    setIsSubmitting(false)
  }

  return (
    <ValidatorForm
      ref={formRef}
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      sx={{
        borderWidth: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
      }}>
      <Typography variant="h3">Title</Typography>
      <Grid container gap={1} sx={{ width: '50%' }}>
        <Grid item sm={12} mb={3}>
          <TextValidator
            label="Name for batch job"
            onChange={handleChange}
            name="name"
            validators={['required', 'maxStringLength:48']}
            errorMessages={['Field is required', 'Max length is 48 characters']}
            value={values.name}
            fullWidth
          />
        </Grid>

        <Typography variant="h3">Column mappings</Typography>

        <Grid item sm={12} mb={3}>
          <SelectValidator
            label="Company name"
            name="companyName"
            id="companyName"
            validators={['matchRegexp:^[^\\-]']}
            errorMessages={['Company name column mapping must be set']}
            onChange={(event) => handleChangeParam(event, 'mapping')}
            value={values.mapping.companyName}
            fullWidth>
            {getOptions().map((item, index) => (
              <MenuItem key={`companyNameMapping_${index}`} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Typography variant="h3">Levels</Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter by position level</InputLabel>
          <Select
            id="filterLevels"
            name="filterLevels"
            value={values.filterLevels}
            label="Filter by levels"
            onChange={handleChange}>
            <MenuItem value="none">No (allow everything)</MenuItem>
            <MenuItem value="predefined">Yes (pick allowed categories)</MenuItem>
          </Select>
        </FormControl>
        {values.filterLevels == 'predefined' && (
          <Grid item sm={12}>
            <FormGroup>
              {Object.entries(levels).map(([key, value], index) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        handleCheckboxChange(event, 'levels')
                      }}
                      checked={(values as any).levels.includes(key)}
                      name={key}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </Grid>
        )}

        <Typography variant="h3" mt={3}>
          Functions
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter by function</InputLabel>
          <Select
            id="filterFunctions"
            name="filterFunctions"
            value={values.filterFunctions}
            label="Filter by functions"
            onChange={handleChange}>
            <MenuItem value="none">No (allow everything)</MenuItem>
            <MenuItem value="predefined">Yes, pick from predefined categories</MenuItem>
            <MenuItem value="custom">Yes, define custom functions</MenuItem>
          </Select>
        </FormControl>
        {values.filterFunctions == 'predefined' && (
          <Grid item sm={12}>
            <FormGroup>
              {Object.entries(functions).map(([key, value], index) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        handleCheckboxChange(event, 'functions')
                      }}
                      checked={(values as any).functions.includes(key)}
                      name={key}
                    />
                  }
                  label={value}
                />
              ))}
            </FormGroup>
          </Grid>
        )}
        {values.filterFunctions == 'custom' && (
          <Grid item sm={12}>
            <FormControl fullWidth>
              <TextValidator
                label="Custom functions"
                onChange={handleChange}
                name="customFunctions"
                validators={['required', 'maxStringLength:300']}
                errorMessages={['Value is required', 'Max length is 300 characters']}
                value={values.customFunctions}
                fullWidth
              />
              <FormHelperText>
                Comma separated list of functions. This will be used both for searching and filtering. e.g. "Sales manager, Marketing director"
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        <Typography variant="h3" mt={3}>
          Additional settings
        </Typography>
        <Grid item sm={12}>
          <Box mb={3}>
            <FormControl>
              <SelectValidator
                label="Country of preference"
                name="country"
                id="country"
                onChange={handleChange}
                value={values.country}
                fullWidth>
                {Object.keys(countries).map((key) => (
                  <MenuItem key={`country_${key}`} value={key}>
                    {countries[key as keyof typeof countries]}
                  </MenuItem>
                ))}
              </SelectValidator>
              <FormHelperText>
                Country of preference is used to give some additional emphasis to given country. This might help provide
                lightly more results when set correctly according to majority of given dataset. Effect is quite low, and
                likely not worth the effort splitting the dataset according to country.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mb={3}>
            <FormControl>
              <SelectValidator
                label="Strict country"
                name="strictCountry"
                id="strictCountry"
                onChange={handleChange}
                value={values.strictCountry}
                fullWidth>
                <MenuItem key={`strict_country_0`} value="0">
                  No
                </MenuItem>
                <MenuItem key={`strict_country_1`} value="1">
                  Yes
                </MenuItem>
              </SelectValidator>
              <FormHelperText>
                Setting strict country limits results only to given country. Please be aware that for some results,
                country information will be missing. With strict option, you will miss some good results too.
              </FormHelperText>
            </FormControl>
          </Box>

          <Box mb={3}>
            <FormControl>
              <SelectValidator
                label="Greed"
                name="greed"
                id="greed"
                onChange={handleChange}
                value={values.greed}
                fullWidth>
                {Object.keys(greed).map((key) => (
                  <MenuItem key={`greed_${key}`} value={key}>
                    {greed[key as keyof typeof greed]}
                  </MenuItem>
                ))}
              </SelectValidator>
              <FormHelperText>
                Greediness defines algorithms used to collect results. Higher the greediness, the more effort and time
                we are putting into collecting maximum number of results. Basic mode provides often good enough results,
                but if the amount of results is very important, you might want to choose advanced.
              </FormHelperText>
            </FormControl>
          </Box>

          <FormControl>
            <SelectValidator
              label="Strictness"
              name="strict"
              id="strict"
              onChange={handleChange}
              value={values.strict}
              fullWidth>
              {Object.keys(greed).map((key) => (
                <MenuItem key={`strict_${key}`} value={key}>
                  {strict[key as keyof typeof strict]}
                </MenuItem>
              ))}
            </SelectValidator>

            <FormHelperText>
              Generally average is a good choice. When quality is greatly preferred over quantity, you can try setting
              strictness to very high. You will likely lose some good results too, but get far less bad results. Very
              low strictness is for experimental use and gives very low quality but high quantity.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item sm={6} mt={2}>
          <Button sx={{ mr: 1 }} variant="outlined" onClick={props.onCancel}>
            Back
          </Button>
          <Button type="submit" disabled={!isFormValid} variant="contained">
            Next
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  )
}
