import React, { useState, useContext } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Backdrop, Button, CircularProgress, Container, Typography, useTheme } from '@mui/material'

import { AuthContext } from '../../contexts/authContext'
import * as base64 from 'base-64'

export const AutoLogin: React.FunctionComponent<{}> = () => {
  const nav = useNavigate()

  const { challenge } = useParams()
  const auth = useContext(AuthContext)
  const theme = useTheme()

  React.useEffect(() => {
    const { email, token } = JSON.parse(base64.decode(challenge ?? ''));
    
    (async () => {
        try {
            await auth.signInWithChallenge(email, token);
            nav('/analysis')
        } catch (err) {
            console.log(err);
        }
    })();
  }, [])

  return (
    <Backdrop sx={{ color: theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 100000 }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
