import { Box, LinearProgress, Skeleton, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent, Ref } from 'react';

interface EnumViewProps {
  title: string;
  selectedIndex: number;
  options: string[];
  sx?: any;
  isLoading: boolean;
}

export const EnumView = React.forwardRef((props: EnumViewProps, ref) => {
  const { sx, selectedIndex, options, isLoading, ...tooltipProps } = props;
  return (
    <Box ref={ref as any} {...tooltipProps}>
      <Typography variant="body2">{props.title}</Typography>

      <Box sx={{display: 'flex'}}>
        <Box sx={{ width: '200px' }}>
          {props.isLoading ? <Skeleton height={20} width={200} /> :
          <LinearProgress color="secondary" sx={{mt: '5px', borderRadius: '3px', height: '10px'}} variant="determinate" value={Math.round((100 * props.selectedIndex) / (props.options.length - 1))} />}
        </Box>
        <Box sx={{ml: 1}}>{props.options[props.selectedIndex]}</Box>
      </Box>
    </Box>
  );
});
