import { ArrowDropDown, Warning } from '@mui/icons-material'
import {
  Box,
  Button,
  ClickAwayListener,
  InputBase,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material'
import React from 'react'

interface Position {
  id?: number
  company: string
  title?: string
  email?: string
}

interface ReplaceInputProps {
  company: string
  setPosition: (value: Position) => void
  suggestedPositions: Position[]
  isModified: boolean
  errorCount: number
}

export const UpdatePosition = (props: ReplaceInputProps) => {
  const anchorRef = React.useRef<any>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {props.errorCount > 0 && !props.isModified && <Warning color="warning" />}{' '}
      <InputBase
        sx={{
          border: 0,
          borderBottom: '1px',
          ...(props.isModified ? { backgroundColor: '#FFCCCB' } : {}),
        }}
        value={props.company}
        onChange={(e: any) => props.setPosition({ company: e.target.value })}></InputBase>
      {props.suggestedPositions.length > 0 && <Button onClick={() => setOpen(!open)} ref={anchorRef}>
        <ArrowDropDown />
      </Button>}
      <>
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Paper sx={{ p: 1, minWidth: '500px' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Suggested positions:</Typography>
              <List sx={{ p: 0.2, zIndex: 1000000, minWidth: '500px' }}>
                {props.suggestedPositions.map((position) => (
                  <ListItem
                    sx={{ backgroundColor: '#f0f0f0', mb: 0.2 }}
                    alignItems="flex-start"
                    key={position.id ?? position.company}
                    secondaryAction={
                      <Button
                        onClick={(event) => {
                          props.setPosition(position)
                          setOpen(false)
                        }}>
                        Use
                      </Button>
                    }>
                    <ListItemText
                      primary={position.company}
                      secondary={
                        <>
                          {position.title}
                          <br />
                          {position.email ?? 'Email not available'}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              {props.suggestedPositions.length === 0 && <Typography>No known positions available</Typography>}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    </>
  )
}
