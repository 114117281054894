import React from 'react'

import { Box, Alert, Container, Typography, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from '../../contexts/authContext'
import { SignInForm } from '../../forms/signInForm'

export const SignIn: React.FunctionComponent<{}> = () => {
  const [error, setError] = React.useState('')

  const nav = useNavigate()

  const authContext = React.useContext(AuthContext)

  const onSignIn = async (email: string, password: string) => {
    try {
      await authContext.signInWithPassword(email, password)
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        nav('verify')
      } else {
        setError(err.message)
      }
    }
  }

  return (
    <>
      <Typography sx={{ mb: 3, marginRight: 'auto' }} component="h1" variant="h5">
        Sign in
      </Typography>

      <SignInForm onSubmit={onSignIn} />

      {error && (
        <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
          {error}
        </Alert>
      )}

      <Box m={1} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Link variant="body2" href="#/signup">
          Sign up
        </Link>
        <Link variant="body2" href="#/auth/forgotpassword">
          Forgot password?
        </Link>
      </Box>
    </>
  )
}
