import { ArrowDropDown, Warning } from '@mui/icons-material'
import {
  Button,
  ClickAwayListener,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'

interface ReplaceInputProps {
  value: string
  setValue: (value: string) => void
  replaceOptions: string[]
  isModified: boolean
  errorCount: number
}

export const ReplaceInput = (props: ReplaceInputProps) => {
  const anchorRef = React.useRef<any>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {props.errorCount > 0 && !props.isModified && <Warning color="warning" />}{' '}
      <InputBase
        sx={{
          border: 0,
          borderBottom: '1px',
          ...(props.isModified ? { backgroundColor: '#FFCCCB' } : {}),
        }}
        value={props.value}
        onChange={(e: any) => props.setValue(e.target.value)}></InputBase>
      {props.replaceOptions.length > 0 && (
        <>
          <Button onClick={() => setOpen(!open)} ref={anchorRef}>
            <ArrowDropDown />
          </Button>
          <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.replaceOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => {
                        props.setValue(option)
                        setOpen(false)
                      }}>
                      <Typography mr={1}>Replace with</Typography>{' '}
                      <Typography sx={{ textDecoration: 'underline' }}>{option}</Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>
        </>
      )}
    </>
  )
}
