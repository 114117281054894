import {
  Box,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  RadioProps,
  Skeleton,
  Tooltip,
  Typography,
  TypographyProps,
  styled
} from '@mui/material';
import React from 'react';

interface ContactPersonalityInsightsProps {
  analysis: any;
  isLoading: boolean;
}

const PropTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '14px',
  mt: 1,
  color: 'rgba(0, 0, 0, 0.6)'
}));

const PropValue = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '16px'
}));

const CtrlRadio = styled(Radio)<RadioProps>(({ theme }) => ({
  paddingRight: '3px',
  paddingBottom: '3px',
  paddingTop: '3px',
}));

const CtrlLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  ml: 0,
  '& .MuiFormControlLabel-label': { fontSize: '14px' },
}));

export const ContactPersonalityInsights = (props: ContactPersonalityInsightsProps) => {
  const [lifeCycleStage, setLifeCycleStage] = React.useState('general');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLifeCycleStage((event.target as HTMLInputElement).value);
  };

  return <>
    <Typography variant="h6">Personality insights</Typography>

    <Tooltip title="Use this HubSpot AI style for the tone of an email" placement="left">
      <Box>
        <PropTitle>AI Assistant tone of email</PropTitle>
        <PropValue>
          {props.isLoading ? <Skeleton width={100} /> : props.analysis?.personalityInsights?.toneOfEmail}
        </PropValue>
      </Box>
    </Tooltip>

    <Tooltip
      title="Insight highlights to remember and use with this person to match his/her personal style"
      placement="left"
    >
      <Box>
        <PropTitle sx={{ mt: 1 }}>Communication guidelines</PropTitle>

        <FormControl>
          <RadioGroup onChange={handleChange} value={lifeCycleStage} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
            <CtrlLabel defaultChecked={true} value="general" control={<CtrlRadio size="small" />} label="General" />
            <CtrlLabel value="prospecting" control={<CtrlRadio size="small"  />} label="Prospecting" />
            <CtrlLabel value="meeting" control={<CtrlRadio size="small"  />} label="Meeting" />
            <CtrlLabel value="followup" control={<CtrlRadio size="small"  />} label="Followup" />
            <CtrlLabel value="offer" control={<CtrlRadio size="small"  />} label="Offer" />
            <CtrlLabel value="closing" control={<CtrlRadio size="small"  />} label="Closing" />
          </RadioGroup>
        </FormControl>
        <List
          sx={{
            listStyle: 'decimal',
            pl: 2,
            pt: 0,
            '& .MuiListItem-root': {
              display: 'list-item'
            }
          }}
        >
          {props.isLoading
            ? [1, 2, 3, 4, 5].map((insight: any) => (
                <ListItem>
                  <Skeleton width={250} />
                </ListItem>
              ))
            : props.analysis?.personalityInsights?.guide?.map((insight: any) => (
                <>
                  <ListItem>{insight.insight[lifeCycleStage]}</ListItem>
                </>
              ))}
        </List>{' '}
      </Box>
    </Tooltip>
  </>
};
