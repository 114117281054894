import { styled, alpha } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Menu,
  MenuProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import React, { useEffect } from 'react'

const DenseTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-input': { paddingTop: 0 },
  grow: 1,
  width: '100%',
  backgroundColor: 'transparent',
}))

interface CompanyFinderInputsProps {
  onSearch: (contactProps: any) => Promise<void>
  myRef: any
}

export const CompanyFinderInputs = (props: CompanyFinderInputsProps) => {
  const [queryErrors, setQueryErrors] = React.useState<any>({})
  const [query, setQuery] = React.useState<any>({})
  const [isLoading, setIsLoading] = React.useState(false)

  props.myRef.current = {
    reset: () => {
      setQuery({})
    },

    searchWith: async (query: any) => {
      setQuery(query)
      setIsLoading(true)
      await props.onSearch(query)
      setIsLoading(false)
    },
  }

  React.useEffect(() => {
    validate()
  }, [query])

  const validate = () => {
    let errors: any = {}

    if (!query.name) {
      errors['name'] = 'Company name is required'
    } else if (query.name.length < 2) {
      errors['name'] = 'Company name must have at least length of 2 characters'
    }

    setQueryErrors(errors)
  }

  return (
    <Box
      sx={{
        mb: 0.5,
        mt: 1,
        backgroundColor: '#eee',
        display: 'flex',
        alignItems: 'center',
        pl: 3,
        pr: 3,
        pt: 2,
        pb: 2,
      }}>
      <Box sx={{ ml: 1, flexGrow: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
      
        <Box
          sx={{
            display: 'flex',
            width: '100%',
          }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <DenseTextField
              helperText={queryErrors['name']}
              id="name"
              sx={{
                mb: 2,
              }}
              label="Company name"
              value={query.name}
              variant="standard"
              onChange={(e) => setQuery({ ...query, name: e.target.value })}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: 'auto', mt: 1, display: 'flex' }}>
          <LoadingButton
            size="small"
            variant="contained"
            color="secondary"
            disabled={Object.keys(queryErrors).length > 0}
            onClick={async () => {
              setIsLoading(true)
              await props.onSearch(query)
              setIsLoading(false)
            }}
            loading={isLoading}>
            Search
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  )
}
