import { Box, Container, Typography } from '@mui/material'
import { Outlet } from 'react-router-dom';

export const NarrowLayout = () => (
  <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box sx={{ mb: 5 }}>
        <img src="logo-dark.png" alt="Traitspotter logo" height="40px" />
      </Box>

      <Outlet />
    </Box>
  </Container>
)
