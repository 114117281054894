import { Box, Divider, Button, Chip, Breadcrumbs, Typography, Tabs, Tab } from '@mui/material';
import { Refresh, CheckCircle, Sync, Help, Error, Create } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../components/DataTable';
import { getApiV2 } from '../../libs/api';
import { DateTime } from 'luxon';

export const BatchList = () => {
  const nav = useNavigate();

  const auth = React.useContext(AuthContext)

  const [currentTab, setCurrentTab] = React.useState(0);
  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [items, setItems] = React.useState([]);
  
  const headers = [
    {
      label: 'Created at',
      field: 'createdAt',
    },
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Row count',
      field: 'count',
    },
    {
      label: 'Status',
      field: 'status',
      render: (data: any) => (
        <>
          {data === 'CREATED' && <Chip variant="outlined" size="small" label="initializing" icon={<Sync />} color="info" />}
          {data === 'IN_PROGRESS' && <Chip variant="outlined" size="small" label="in progress" icon={<Sync />} color="info" />}
          {data === 'COMPLETED' && <Chip variant="outlined" size="small" label="completed" icon={<CheckCircle />} color="success" />}
          {data === 'FAILED' && <Chip variant="outlined" size="small" label="failed" icon={<Error />} color="error" />}
          {data === 'ABORTED' && <Chip variant="outlined" size="small" label="aborted" icon={<Help />} color="warning" />}

        </>
      ),
    },
    {
      label: 'Detailed status',
      field: 'detailedStatus',
      render: (data: any) => (
        <>
          {data.quantityInProgress > 0 && <Chip variant="outlined" size="small" label={`${data.quantityInProgress} processing`} icon={<Sync />} color="info" />} 
          {data.quantityNotFound > 0 && <Chip variant="outlined" size="small" label={`${data.quantityNotFound} not found`} icon={<Help />} color="warning" />}
          {data.quantityCompleted > 0 && <Chip variant="outlined" size="small" label={`${data.quantityCompleted} completed`} icon={<CheckCircle />} color="success" />}
          {data.quantityFailed > 0 && <Chip variant="outlined" size="small" label={`${data.quantityFailed} failed`} icon={<Error />} color="error" hidden={data.quantityFailed === 0} />}
          {data.quantityAborted > 0 && <Chip variant="outlined" size="small" label={`${data.quantityAborted} aborted`} icon={<Help />} color="warning" hidden={data.quantityAborted === 0} />}

        </>
      ),
    },
    {
      label: 'Actions',
      field: 'actions',
      render: (id: string) => (
        <Button variant="outlined" onClick={() => nav(`/batches/${id}`)}>View</Button>
      )
    }
  ]

  const fetchData = async () => {
    setIsRefreshing(true);

    try {
      const batchJobs = await (await getApiV2(auth)).get('/batches', {
        params: {
          type: currentTab === 0 ? 'PROFILING' : 'SEARCH'
        }
      })

      setItems(batchJobs.data.map((v: any) => ({
          id: v.id,
          createdAt: DateTime.fromSQL(v.created_at).toLocaleString({...DateTime.DATETIME_SHORT, hour: '2-digit'}), // toFormat('DD.MM.YYYY HH:mm:ss'),
          name: v.name,
          count: v.count,
          status: v.status,
          detailedStatus: {
            quantityInProgress: v.quantity_in_progress,
            quantityFailed: v.quantity_failed,
            quantityCompleted: v.quantity_completed,
            quantityNotFound: v.quantity_not_found,
            quantityAborted: v.quantity_aborted
          },
          actions: v.id
        })))
    } catch (err) {
      console.log('Failed to get data ' + err)
    }

    setIsRefreshing(false);

    return [];
  }

  React.useEffect(() => {
    fetchData()
  }, [currentTab])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{m: 1}}>
          <Breadcrumbs>
            <Typography color="text.primary">Batches</Typography>
          </Breadcrumbs>
        </Box>

        <div>
          <LoadingButton sx={{mr: 1}} variant="outlined" startIcon={<Refresh />} onClick={fetchData} loadingPosition="start" loading={isRefreshing}>Refresh</LoadingButton>
          <Button sx={{mr: 1}} startIcon={<Create />} variant="contained" onClick={() => nav('/batches/create-profiling')}>
            Create new analysis batch
          </Button>
          <Button startIcon={<Create />} variant="contained" onClick={() => nav('/batches/create-search')}>
            Create new search batch
          </Button>          
        </div>
      </div>

      <Tabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label="Analysis batches" />
        <Tab label="Search batches" />
      </Tabs>

      <Divider sx={{mb:2, mt:2}} />

      <DataTable items={items} isLoading={isRefreshing} headers={headers} />
    </>
  )
}
