import {
  Link,
  Box,
  Divider,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import React from 'react'
import { AuthContext } from '../../../contexts/authContext'
import { getApi } from '../../../libs/api'
import { DataTable } from '../../../components/DataTable'
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'

export const SuperAdminCustomerView = () => {
  const { id } = useParams()

  const auth = React.useContext(AuthContext)

  const [isRefreshing, setIsRefreshing] = React.useState(true)
  const [customer, setCustomer] = React.useState({ name: 'unknown', users: [], integrations: [] })

  const userHeaders = [
    {
      label: 'Created at',
      field: 'created_at',
    },
    {
      label: 'Email',
      field: 'email',
    },
  ]

  const integrationHeaders = [
    {
      label: 'Created at',
      field: 'created_at',
    },
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Status',
      field: 'status',
    },
  ]

  const fetchData = async () => {
    try {
      setIsRefreshing(true)
      const response = await (await getApi(auth)).get(`/admin/customers/${id}`)

      setCustomer(response.data)
    } catch (err) {
      console.log('Failed to get data ' + err)
    }

    setIsRefreshing(false)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ m: 1 }}>
          <Breadcrumbs>
            <Typography color="text.primary">SuperAdmin</Typography>
            <Link href="#/admin/customers">Customers</Link>
            <Typography color="text.primary">{customer.name}</Typography>
          </Breadcrumbs>
        </Box>
      </div>

      <Divider sx={{ mb: 2, mt: 2 }} />

      <Box>
        <Typography color="text.secondary">Name</Typography>
        <Typography gutterBottom>{customer.name !== '' ? customer.name : 'Unknown'}</Typography>
      </Box>

      <Typography sx={{ fontSize: '20px' }}>Integrations</Typography>
      <DataTable
        items={customer.integrations.map((integration: any) => ({
          ...integration,
          created_at: DateTime.fromSQL(integration.created_at).toLocaleString({
            ...DateTime.DATETIME_SHORT,
            hour: '2-digit',
          }),
        }))}
        isLoading={isRefreshing}
        headers={integrationHeaders}
      />

      <Typography sx={{ fontSize: '20px', mt: 2 }}>Users</Typography>
      <DataTable
        items={customer.users.map((user: any) => ({
          ...user,
          created_at: DateTime.fromSQL(user.created_at).toLocaleString({
            ...DateTime.DATETIME_SHORT,
            hour: '2-digit',
          }),
        }))}
        isLoading={isRefreshing}
        headers={userHeaders}
      />
    </>
  )
}
