import { Box, Divider, Button, Chip, Breadcrumbs, Typography } from '@mui/material';
import { AccessTime, Block, Create, Delete, LinkOff, LockOpen, Pause, PlayArrow } from '@mui/icons-material'
import React from 'react'
import { AuthContext } from '../../../contexts/authContext'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { enqueueSnackbar } from 'notistack';
import { getApiV2 } from '../../../libs/api';
import { DateTime } from 'luxon';

export const SuperAdminIntegrationList = () => {
  const nav = useNavigate();

  const auth = React.useContext(AuthContext)

  const [isRefreshing, setIsRefreshing] = React.useState(false);
  const [items, setItems] = React.useState([]);
  
  const headers = [
    {
      label: 'Created at',
      field: 'createdAt',
    },
    {
      label: 'Company',
      field: 'company'
    },
    {
      label: 'Name',
      field: 'name',
    },
    {
      label: 'Platform',
      field: 'platform',
    },
    {
      label: 'Status',
      field: 'status',
      render: (data: any) => (
        <>
          {data === 'CREATED' && <Chip variant="outlined" size="small" label="not connected" icon={<LinkOff />} color="info" />}
          {data === 'CONNECTED' && <Chip variant="outlined" size="small" label="pending approval" icon={<Block />} color="info" />}
          {data === 'ACTIVE' && <Chip variant="outlined" size="small" label="active" icon={<PlayArrow />} color="success" />}
          {data === 'PAUSED' && <Chip variant="outlined" size="small" label="paused" icon={<Pause />} color="info" />}
          {data === 'DELETED' && <Chip variant="outlined" size="small" label="deleted" icon={<Delete />} color="info" />}

        </>
      ),
    },
    {
      label: 'Actions',
      field: 'actions',
      render: (id: string) => (
        <Button variant="outlined" onClick={() => nav(`/admin/integrations/${id}`)}>View</Button>
      )
    }
  ]

  const fetchData = async () => {
    setIsRefreshing(true);

    try {
      const response = await (await getApiV2(auth)).get('/admin/integrations')

      setItems(response.data.map((v: any) => ({
          id: v.id,
          createdAt: DateTime.fromSQL(v.created_at).toLocaleString({...DateTime.DATETIME_SHORT, hour: '2-digit'}),
          name: v.name,
          company: v.company,
          status: v.status,
          actions: v.id,
          platform: v.type
        })))
    } catch (err) {
      enqueueSnackbar('Update failed. Try refreshing the page.', { variant: 'error' })
      console.log('Failed to get data ' + err)
    }

    setIsRefreshing(false);

    return [];
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Box sx={{m: 1}}>
          <Breadcrumbs>
            <Typography color="text.primary">SuperAdmin</Typography>
            <Typography color="text.primary">Integrations</Typography>
          </Breadcrumbs>
        </Box>
      </div>

      <Divider sx={{mb:2, mt:2}} />

      <DataTable items={items} isLoading={isRefreshing} headers={headers} />
    </>
  )
}
