import React from 'react'
import {
  Alert,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TSAppBar } from '../components/TSAppBar'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorRoute } from '../routes/ErrorRoute'
import { AdminPanelSettings, Business, Extension, Group, Key, ListAlt, People, PersonSearch, Settings } from '@mui/icons-material'
import { AuthContext } from '../contexts/authContext'

const drawerWidth = 280

export const NormalLayout = () => {
  const nav = useNavigate()
  const location = useLocation()
  const auth = React.useContext(AuthContext)
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const drawerContainerRef = React.useRef()

  const menuItems = [
    {
      title: 'Analysis',
      items: [
        {
          label: 'Company finder',
          link: '/analysis/company-finder',
          icon: <Business />,
        },
        {
          label: 'Analysis builder',
          link: '/analysis',
          icon: <PersonSearch />,
        },
        {
          label: 'Tasks',
          link: '/tasks/create',
          icon: <PersonSearch />,
          groups: ['resource-task'],
        },
        {
          label: 'Batches',
          link: '/batches',
          icon: <ListAlt />,
          groups: ['resource-batch'],
        },
      ],
    },
    {
      title: 'Integration',
      items: [
        {
          label: 'API keys',
          link: '/apikeys',
          icon: <Key />,
          groups: ['resource-apikey'],
        },
        {
          label: 'HubSpot',
          link: '/integrations',
          icon: <Extension />,
        },
      ],
    },
    {
      title: 'Preferences',
      items: [
        {
          label: 'Preferences',
          link: '/preferences',
          icon: <Settings />,
        },
      ],
    },
    {
      title: 'Admin',
      items: [
        {
          label: 'Users',
          link: '/admin/users',
          icon: <People />,
          groups: ['role-admin'],
        }
      ]
    },
    {
      title: 'SuperAdmin',
      items: [
        {
          label: 'Integrations',
          link: '/superadmin/integrations',
          icon: <Extension />,
          groups: ['role-superadmin'],
          indent: 1,
        },
        {
          label: 'Customers',
          link: '/superadmin/customers',
          icon: <Group />,
          groups: ['role-superadmin'],
          indent: 1,
        },
      ],
    },
  ]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <List>
      {menuItems.map((group, index) => (
        <>
          {group.items.filter((item) =>
            !item.groups || (item.groups ?? []).some((grp) => auth.sessionInfo?.groups?.includes(grp))
          ).length > 0 && (
            <ListItem key={group.title} sx={{ m: 0, p: 0, pl: '8px', mb: 0, background: '#eee', marginTop: index == 0 ? '24px' : '32px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  flexGrow: 1,
                  width: '100%',
                  p: '8px',
                  color: '#999',
                }}>
                <ListItemText primary={group.title} />
              </Box>
            </ListItem>
          )}

          {group.items
            .filter((item) => (item.groups ?? []).every((grp) => auth.sessionInfo?.groups?.includes(grp)))
            .map((item) => (
              <ListItem
                key={item.label}
                sx={{ m: 0, p: 0, pl: '8px', mb: 0, background: item.link ? 'transparent' : '#eee' }}>
                <Box
                  sx={{
                    backgroundColor: location.pathname == item.link ? '#ddd' : 'transparent',
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexGrow: 1,
                    width: '100%',
                    p: '0px',
                  }}>
                  <ListItemButton sx={{ p: '8px' }} onClick={() => nav(item.link)}>
                    <ListItemIcon sx={{}}>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </Box>
              </ListItem>
            ))}
        </>
      ))}
    </List>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <TSAppBar
        onSignOut={() => {
          auth.signOut()
        }}
        username={auth.attrInfo?.email}
      />

      <Box
        ref={drawerContainerRef}
        component="nav"
        sx={{ zIndex: 1, width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#eee' },
          }}
          open>
          <>
            <Toolbar />
            <Divider />
            {drawer}
          </>
        </Drawer>
      </Box>
      <ErrorBoundary FallbackComponent={ErrorRoute}>
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '76px', width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          {auth.sessionInfo?.groups?.length === 0 && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              This account has not yet been approved. Some functionality is restricted.
            </Alert>
          )}

          <Outlet />
        </Box>
      </ErrorBoundary>
    </Box>
  )
}
