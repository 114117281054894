import React from 'react'
import { Avatar, Box, Grid, Link, Skeleton, Typography, TypographyProps, styled, useTheme } from '@mui/material'
import { ContactDemographics } from './ContactDemographics'
import { ContactPersonality } from './ContactPersonality'
import { ContactPositions } from './ContactPositions'
import { ContactPersonalityInsights } from './ContactPersonalityInsights'
import { LinkedIn } from '@mui/icons-material'

interface ContactProps {
  analysis: any
  data: any
  relatedContacts: any[]
  onCancelImport: (linkedinUrl: string) => void
  onUseCompany?: (contactData: any) => void
  isLoading: boolean
  isEmailsLoading: boolean
}

export const Contact: React.FunctionComponent<ContactProps> = (props: ContactProps) => {
  const theme = useTheme()

  return (
    <Grid container columnSpacing={8}>
      <Grid item xs={12} sm={4}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
            {props.analysis.firstName} {props.analysis.lastName}
          </Typography>

          <Box sx={{ width: '140px', justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
            {props.isLoading ? (
              <Skeleton variant="circular" width={96} height={96} sx={{ mb: 2 }} />
            ) : (
              <Avatar
                sx={{ width: '96px', height: '96px', mb: 2 }}
                alt={`${props.analysis.firstName} ${props.analysis.lastName}`}
                src={props.analysis.profileImageUrl}
              />
            )}

            <Box sx={{ justifyContent: 'center', display: 'flex', gap: '5px', width: '200px' }}>
              {props.isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={24} height={24} sx={{ mr: 0.5 }} />
                  <Skeleton variant="rectangular" width={48} height={24} />
                </>
              ) : (
                <>
                  <Link href={props.analysis.linkedinUrl} target="_blank">
                    <LinkedIn sx={{ color: theme.palette.primary.main, ml: 1 }} />
                  </Link>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <ContactPositions
          isLoading={props.isLoading || !props.analysis || !props.data}
          isEmailsLoading={props.isEmailsLoading}
          analysis={props.analysis}
          data={props.data}
          onUseCompany={props.onUseCompany}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Box sx={{ border: '1px solid rgb(38, 34, 98)', backgroundColor: 'rgba(38, 34, 98, 0.05)', p: 2, mb: 2 }}>
          <ContactPersonalityInsights isLoading={props.isLoading} analysis={props.analysis} />
        </Box>
        <ContactPersonality isLoading={props.isLoading} analysis={props.analysis} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <ContactDemographics contact={props.analysis} isLoading={props.isLoading} />
      </Grid>
    </Grid>
  )
}
